import useSWR from 'swr'
import client from '../../../../client'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import cardSx from '~/core/sx/cardSx'
import sxFlexStack from '~/core/sx/sxFlexStack'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import {
  FormContainer,
  TextFieldElement,
  useForm,
  useFormContext,
} from 'react-hook-form-mui'
import useDistributorEstimate from './useDistributorEstimate'
import { useParams } from 'react-router'
import SubmitButton from '~/components/Data/SubmitButton'
import { Close, Download, OpenInNew, Send } from '@mui/icons-material'
import flexSplitSx from '~/core/sx/flexSplitSx'
import { usd } from '~/core/utils/formatNumber'
import tablePaddingSx from '~/core/sx/tablePaddingSlimSx'
import ProposalDetails from './ProposalDetails'
import FanLoader from '~/components/Data/FanLoaader'
import LoadingIndicators, {
  LoadingBackdrop,
} from '~/components/Data/LoadingIndicators'
import EstimateFileUpload from './EstimateFileUpload'
import LinkToModelNumber from '../../../ProductCatalogue/LinkToModelNumber'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

const WatchedLineTotal = ({ index, quantity }) => {
  const { watch } = useFormContext()
  const unitPrice = watch(`lineItems.${index}.customUnitPrice`)
  return usd.format((unitPrice || 0) * quantity)
}

export default function CompleteEstimate() {
  const { distributorEstimateId } = useParams()
  const {
    data: estimate,
    mutate,
    upload,
    completeEstimate,
    cancelEstimate,
    isLoading,
    isSubmitting,
    ...rest
  } = useDistributorEstimate({ distributorEstimateId })

  const formContext = useForm()
  const { watch, register } = formContext
  const watchedLineItems = watch('lineItems')

  const lineItems =
    estimate?.status === 'requested' ? watchedLineItems : estimate?.lineItems

  const total = lineItems?.reduce(
    (total, { customUnitPrice, quantity }, i) =>
      total + (customUnitPrice || 0) * estimate.lineItems[i].quantity,
    0
  )

  if (isLoading) return <FanLoader isLoading />

  console.log('DPOIDPDOI', estimate)

  return (
    <>
      <ProposalDetails />

      {!estimate?.status.startsWith('cancelled') && (
        <FormContainer formContext={formContext}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card sx={{ ...cardSx, ...sxFlexStack }}>
                <LoadingBackdrop active={isSubmitting} />
                <Box
                  sx={{
                    ...flexSplitSx,
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  <Typography variant="h5">Proposal line items</Typography>

                  {estimate.attachmentFileUrl && (
                    <Button
                      target="_blank"
                      href={`${CDN_DOMAIN}${estimate.attachmentFileUrl}`}
                      startIcon={<Download />}
                    >
                      Download attachment
                    </Button>
                  )}
                </Box>
                <TableContainer>
                  <Table
                    sx={{
                      ...tablePaddingSx,
                      '& td:last-child:not(:first-child), & th:last-child:not(:first-child)':
                        {
                          textAlign: 'right',
                        },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Model number</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell sx={{ minWidth: 120 }}>
                          Price per unit
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }}>Line total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {estimate?.lineItems?.map(
                        (
                          {
                            modelNumber,
                            quantity,
                            category = '',
                            description = '',
                            customUnitPrice,
                          },
                          i
                        ) => (
                          <TableRow>
                            <TableCell>
                              <LinkToModelNumber modelNumber={modelNumber} />
                              <input
                                type="hidden"
                                {...register(`lineItems.${i}.modelNumber`, {
                                  value: modelNumber,
                                })}
                                value={modelNumber}
                              />
                            </TableCell>
                            <TableCell>{description}</TableCell>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>
                              {estimate?.status === 'requested' ? (
                                <TextFieldElement
                                  type="number"
                                  name={`lineItems.${i}.customUnitPrice`}
                                  InputProps={{
                                    startAdornment: '$ ',
                                    inputProps: { min: 0.01 },
                                  }}
                                  sx={{ maxWidth: 110 }}
                                  disabled={estimate.status === 'completed'}
                                  size="small"
                                  rules={{
                                    min: {
                                      value: 0,
                                      message: 'Must be greater than 0',
                                    },
                                    required: { value: true, message: '' },
                                  }}
                                />
                              ) : (
                                usd.format(customUnitPrice)
                              )}
                            </TableCell>
                            <TableCell>
                              {estimate?.status === 'requested' ? (
                                <WatchedLineTotal
                                  index={i}
                                  quantity={quantity}
                                />
                              ) : (
                                usd.format(customUnitPrice * quantity)
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Typography variant="h4" sx={{ py: 2 }}>
                            Total: {usd.format(total || 0)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              {estimate?.status === 'requested' && (
                <Card sx={{ ...cardSx, ...sxFlexStack }}>
                  <LoadingIndicators isSubmitting={isSubmitting} />

                  <Typography variant="h5">Supplementary attachment</Typography>

                  <Typography>
                    doc, docx, pdf, ppt, pptx and otf files are accepted.
                  </Typography>
                  <EstimateFileUpload />

                  <Typography variant="h5">Message</Typography>
                  <TextFieldElement
                    name="distributorMessage"
                    multiline
                    rows={4}
                    fullWidth
                  />
                  <Box sx={{ ...flexSplitSx }}>
                    <Button
                      color="error"
                      endIcon={<Close />}
                      onClick={cancelEstimate}
                    >
                      Decline
                    </Button>

                    <SubmitButton
                      color="success"
                      endIcon={<Send />}
                      onClick={formContext.handleSubmit(completeEstimate)}
                      isSubmitting={isSubmitting}
                    >
                      Send estimate
                    </SubmitButton>
                  </Box>
                </Card>
              )}
            </Grid>
          </Grid>
        </FormContainer>
      )}
    </>
  )
}
