import React from 'react'
import useSearchParams from '../router/useParams'

const transition = { duration: 0.3 }

export const stepRouterVariants = {
  enter: direction => {
    return {
      x: direction === 'prev' ? '100%' : '-100%',
      // opacity: 0,
      transition,
    }
  },
  center: {
    // zIndex: 1,
    x: 0,
    opacity: 1,
    transition,
  },
  done: direction => {
    return {
      // zIndex: 0,
      x: direction === 'prev' ? '-100%' : '100%',
      // opacity: 0,
      transition,
      position: 'absolute',
    }
  },
}

export default function useParamsStepRouter({
  steps,
  key = 'step',
  scrollToTop,
  options = {},
}) {
  const [params, setParams] = useSearchParams()

  const activeName = params[key] || steps[0].name
  const activeStep = steps.find(step => step.name === activeName) || steps[0]
  const activeIndex = steps.findIndex(step => step.name === activeName) || 0

  const [direction, setDirection] = React.useState('next')

  const stepTo = input => {
    const newIndex =
      typeof input === 'number'
        ? input
        : steps.findIndex(step => step.name === input)

    if (
      typeof newIndex === 'undefined' ||
      newIndex < 0 ||
      newIndex >= steps.length
    )
      return

    setDirection(newIndex < activeIndex ? 'next' : 'prev')
    setParams(p => ({ ...p, [key]: steps[newIndex].name }), options)
    if (scrollToTop)
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'instant' }))
  }

  const isNextStep = activeIndex < steps.length - 1 // ? activeIndex + 1 : false
  const isPrevStep = activeIndex > 0 // ? activeIndex - 1 : false

  const onNextClick = () => stepTo(activeIndex + 1)
  const onPrevClick = () => stepTo(activeIndex - 1)

  return {
    ...activeStep,
    direction,
    index: activeIndex,
    stepTo,
    onNextClick,
    onPrevClick,
    isNextStep,
    isPrevStep,
    // pagination: {
    //   page,
    //   setPage,
    //   onNextClick,
    //   onPrevClick,
    //   isNextPage,
    //   isPrevPage,
    //   ...meta,
    // },
  }
}
