const buildSpecQuery = fields =>
  Object.entries(fields || {})
    .filter(([k, v]) => typeof v !== 'undefined')
    .map(([k, v]) =>
      Array.isArray(v)
        ? ` && (${v
            .map(v => `${typeof v === 'number' ? v : `'${v}'`} in spec.${k}`)
            .join(' || ')})`
        : ` && ${typeof v === 'number' ? v : `'${v}'`} in spec.${k}`
    )
    .join('')

export default buildSpecQuery
