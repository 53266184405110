import { Box, Button, Card, Container, Link, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmittingIcon from '~/components/Data/SubmittingIcon'
import sharedProps from '~/components/sharedProps'
import { cognitoPassword } from '~/core/forms/validation'

const variants = {
  initial: { y: '-100%' },
  animate: { y: 0 },
  exit: { y: '-100%' },
}

const defaultValues = {
  // username: '',
  password: '',
}

export default function SignUp() {
  const navigate = useNavigate()
  const location = useLocation()
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  // const [searchParams] = useSearchParams()
  // console.log(searchParams.get())

  const formContext = useForm({
    defaultValues,
  })

  React.useEffect(() => {
    if (client.isSignedIn()) navigate('/app')
  }, [])

  const submitButtonRef = React.useRef()

  const handleClick = async data => {
    try {
      setSubmitting(true)
      const res = await client.call('auth/signup?campaign=demo', data)
      enqueueSnackbar('Please check your email to confirm')
    } catch (e) {
      console.error('signup error caught ', e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      as={motion.div}
      variants={variants}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
        <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
          Sign up for a demo account
        </Typography>
      </Box>
      <Card sx={{ p: 3, py: 4, position: 'relative' }}>
        {submitted ? (
          <Typography sx={{ textAlign: 'center' }}>
            Thanks for signing up, please check your email for a confirmation
            link.
          </Typography>
        ) : (
          <FormContainer formContext={formContext} onSuccess={handleClick}>
            <TextFieldElement
              fullWidth
              name="email"
              label="Email"
              required
              sx={{ mb: 3 }}
              // autoComplete="username"
              type="email"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
            <PasswordElement
              fullWidth
              name="password"
              label="Password"
              type="password"
              required
              sx={{ mb: 3 }}
              rules={{ pattern: cognitoPassword }}

              // autoComplete="current-password"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
            <TextFieldElement
              fullWidth
              name="firstName"
              label="First name"
              required
              sx={{ mb: 3 }}
            />
            <TextFieldElement
              fullWidth
              name="lastName"
              label="Last name"
              required
              sx={{ mb: 3 }}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={{ flexShrink: 0, alignSelf: 'start' }}
                {...sharedProps.button.save}
                endIcon={<SubmittingIcon active={submitting} />}
                ref={submitButtonRef}
              >
                Sign up
              </Button>
              <div>
                Already a member? <Link href="/sign-in">Sign in</Link>
              </div>
            </Box>
          </FormContainer>
        )}
        <LoadingIndicators isSubmitting={submitting} />
      </Card>
    </Container>
  )
}
