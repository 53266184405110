import { Box } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { motionStepProps } from '~/core/stepper/motionStepProps'

export default ({ direction, name, children }) => {
  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      <AnimatePresence initial={false} custom={direction}>
        <Box key={name} {...motionStepProps} custom={direction}>
          {children}
        </Box>
      </AnimatePresence>
    </Box>
  )
}
