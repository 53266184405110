import { Card, Dialog, Typography } from '@mui/material'
import * as React from 'react'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'

import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'
import AssetList from '~/routes/Company/Assets/AssetList'
import UploadAssets from '~/routes/Company/Assets/UploadAssets'
import useCompanyAssets from '~/routes/Company/Assets/useCompanyAssets'

import EditAssetInfo from '~/routes/Company/Assets/AssetInfo'
import AssetViewport from '~/routes/Company/Assets/AssetViewport'
import DeleteAsset from '~/routes/Company/Assets/DeleteAsset'

const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN

export default function CompanyAssets() {
  // const {
  //   data: assets,
  //   error,
  //   isLoading,
  //   mutate,
  // } = useSWR('assets', client.fetcher)

  const { assets, isLoading, deleteAsset, updateAsset, isUpdating } =
    useCompanyAssets()

  const [asset, setAsset] = React.useState(null)

  const handleClose = () => setAsset(null)

  const handleDelete = () => {
    deleteAsset(asset)
    handleClose()
  }

  console.log('useCompanyAssets', assets, 'setAsset', asset)

  return (
    <>
      {/* Just view the assets
      <Card {...sharedProps.card} sx={{ background: 'red' }}>
        <AssetList assets={assets} />
        <LoadingIndicators {...{ isLoading }} />
      </Card> */}
      <Card {...sharedProps.card}>
        <Typography variant="h3">Your company's assets</Typography>
        <AssetList assets={assets} onDelete={deleteAsset} onClick={setAsset} />
        <LoadingIndicators {...{ isLoading }} />
      </Card>
      <Card {...sharedProps.card}>
        <UploadAssets />
      </Card>
      <Dialog
        scroll="paper"
        disableAutoFocus
        onClose={handleClose}
        open={!!asset}
        PaperProps={{ sx: { p: 2, minWidth: 400 } }}
      >
        <AssetViewport asset={asset} />

        <EditAssetInfo {...{ asset, isUpdating, updateAsset }} sx={{ mt: 3 }} />

        <DeleteAsset onClick={handleDelete} sx={{ mt: 3 }} />
      </Dialog>
    </>
  )
}
