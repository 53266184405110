import { Add, Close } from '@mui/icons-material'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form-mui'

import AnimateFields from '~/core/forms/FieldAnimatedStack'

const getDefaultValuesFromChildrenProps = children =>
  React.Children.toArray(children)
    .filter(child => child?.props.name)
    .reduce(
      (names, child) => ({
        ...names,
        [child.props.name]: child.props.type === 'number' ? 0 : '',
      }),
      {}
    )

export default function FieldArrayStack({
  children,
  defaultValues = getDefaultValuesFromChildrenProps(children),
  name,
  height,
  allowEdit,
  keyField = 'name',
  spacing,
  sx,
  ...rest
}) {
  const {
    fields,
    append,
    remove,
    // swap, move, insert, prepend
  } = useFieldArray({
    name,
    shouldUnregister: true,
  })

  console.log('defaults', { defaultValues, fields })

  return (
    <Stack sx={{ width: '100%', ...sx }}>
      <AnimatePresence>
        {fields.map((field, i) => (
          <AnimateFields spacing={spacing} direction="row" key={field.id}>
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                name: `${name}.${i}.${child.props.name}`,
                key: `${field.id}-${child.props.name}`,
              })
            )}
            {allowEdit && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => remove(i)}>
                  <Close />
                </IconButton>
              </Box>
            )}
          </AnimateFields>
        ))}
      </AnimatePresence>
      {allowEdit && (
        <div>
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => append(defaultValues)}
          >
            {typeof allowEdit === 'string' ? allowEdit : 'Add more'}
          </Button>
        </div>
      )}
    </Stack>
  )
}
