import { Outlet, useMatches, useParams } from 'react-router'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'

import BoardContainer from '~/routes/SalesResources/Provider/BoardContainer'
import { BoardProvider } from '~/routes/SalesResources/Provider/BoardProvider'
import useCard from '~/routes/SalesResources/hooks/useCard'

export default function SalesResources() {
  // const matches = useMatches('/')

  const params = useParams()

  // const { data: card } = useCard(params.cardId)

  // console.log({ matches, params, card })
  return (
    <>
      {/* <AppStyles /> */}
      <BoardProvider>
        <BoardContainer>
          <Outlet />
        </BoardContainer>
      </BoardProvider>
    </>
  )
}
