import { Card, Divider, Typography } from '@mui/material'
import cardSx from '~/core/sx/cardSx'
import sxFlexStack from '~/core/sx/sxFlexStack'
import StepButtons from './StepButtons'
import SummaryProductsTable from './SummaryProductsTable'

export default function Summary({ onNextClick, onPrevClick, ...rest }) {
  return (
    <Card sx={{ ...cardSx, ...sxFlexStack, gap: 2 }}>
      <Typography variant="h4">Replacement summary</Typography>
      <Typography>Suggested replacement equipment.</Typography>

      <Divider />

      <SummaryProductsTable />

      <StepButtons {...{ onNextClick, onPrevClick }} />
    </Card>
  )
}
