import { Card, Divider, Typography } from '@mui/material'
import { FormContainer, ToggleButtonGroupElement } from 'react-hook-form-mui'
import booleanToggleButtonGroupProps from '~/core/forms/booleanToggleButtonGroupProps'
import { findOptionsMap } from '~/core/sanity/findOptions'
import cardSx from '~/core/sx/cardSx'
import sxFlexStack from '~/core/sx/sxFlexStack'
import disabledHelperText from '~/components/disabledHelperText'
import DeselectingToggleButtonGroupElement from '~/core/forms/DeselectingToggleButtonGroupElement'
import useFilteredSanityOptions from '../../FastQuote/Steps/useFilteredSanityOptions'
import { useReplacementGuideState } from '../Provider/ReplacementGuideProvider'
import StepButtons from './StepButtons'

const toggleProps = {
  enforceAtLeastOneSelected: true,
  exclusive: true,
  required: true,
  color: 'primary',
}

export default function ReplacementSystem({
  onNextClick,
  onPrevClick,
  ...rest
}) {
  const state = useReplacementGuideState()
  const tonnage = state.fields.existing?.tonnage
  const options = useFilteredSanityOptions({
    name: 'outletAdapter',
    category: 'outlet',
    fields: {
      outletCategory: 'supplyPlenumAccessory',
      tonnage,
    },
  })

  return (
    <FormContainer {...rest}>
      <Card sx={{ ...cardSx, ...sxFlexStack, gap: 2 }}>
        <Typography variant="h4">Replacement system</Typography>
        <Typography>
          Please provide information about the new installation.
        </Typography>

        <Divider />

        <Typography variant="h4">New blower</Typography>

        <ToggleButtonGroupElement
          name="replacement.orientation"
          label="Blower orientation"
          options={findOptionsMap(['products', 'spec', 'orientation'])?.filter(
            ({ id }) => id !== 'verticalUpflowSingle'
          )}
          {...toggleProps}
        />

        <ToggleButtonGroupElement
          name="replacement.direction"
          label="Blower direction"
          options={findOptionsMap(['products', 'spec', 'direction'])}
          {...toggleProps}
        />

        <DeselectingToggleButtonGroupElement
          name="replacement.outletAdapter"
          label="Outlet adapter"
          options={options}
          {...toggleProps}
          helperText={disabledHelperText(options)}
        />

        <Divider />

        {!state.fields.existing?.heatingCoil && (
          <>
            <Typography variant="h4">New heating coil</Typography>

            <ToggleButtonGroupElement
              label="Do you need to add a hot water coil?"
              name="replacement.heatingCoil"
              {...booleanToggleButtonGroupProps}
              color="primary"
            />
          </>
        )}

        {/* {!state.fields.existing?.coolingCoil && (
          <>
            <Typography variant="h4">New cooling coil</Typography>

            <ToggleButtonGroupElement
              label="Do you need to add a cold water coil?"
              name="replacement.coolingCoil"
              {...booleanToggleButtonGroupProps}
              color="primary"
            />
          </>
        )} */}

        <StepButtons {...{ onPrevClick }} />
      </Card>
    </FormContainer>
  )
}
