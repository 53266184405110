import { Box, Button, Chip, Paper } from '@mui/material'

import { useFormContext } from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import { getRangeFromDays } from '~/routes/Dashboard/useDateRanges'
import AutoTableSearchPopper from './AutoTableSearchPopper'

const hasSameDate = (one, two) => {
  if (![one, two].every(Boolean)) return
  try {
    const isSame =
      new Date(one).toISOString().split('T')[0] ===
      new Date(two).toISOString().split('T')[0]

    return isSame
  } catch (e) {
    console.error(e)
    return
  }
}

const AutoSearchDateRange = ({ name }) => {
  const { watch, setValue, setFocus } = useFormContext()
  const value = watch(name)

  const hasValue = value?.gte || value?.lte

  const handleReset = () => {
    // setValue(`${name}.gte`, null)
    // setValue(`${name}.lte`, null)
    setValue(name, null)
    setFocus(`${name}.gte`)
  }

  return (
    <AutoTableSearchPopper name={name}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        variant="outlined"
        elevation={10}
      >
        <DatePickerElement
          inputProps={{ autoFocus: true }}
          label="From"
          name={`${name}.gte`}
          disableFuture
        />
        <DatePickerElement
          label="To"
          name={`${name}.lte`}
          disableFuture
          transform={{
            output: date => {
              date.setHours(23, 59, 59, 999)
              return date
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: 1,
            maxWidth: 280,
          }}
        >
          <DateRangeOptionButton name={name} label="Today" days={0} />
          <DateRangeOptionButton name={name} label="Yesterday" days={1} />
          <DateRangeOptionButton name={name} label="Last 7 days" days={7} />
          <DateRangeOptionButton name={name} label="Last 4 weeks" days={28} />
          <Button
            variant="text"
            sx={{ py: 0.5 }}
            onClick={handleReset}
            disabled={!hasValue}
          >
            Clear selection
          </Button>
        </Box>
      </Paper>
    </AutoTableSearchPopper>
  )
}

const DateRangeOptionButton = ({
  name,
  label = 'Last week',
  days = 7,
  offset = 0,
}) => {
  const { watch, setValue, reset, setFocus } = useFormContext()
  const value = watch(name)

  const [gte, lte] = getRangeFromDays(days, offset)

  const handleClick = () => {
    console.log(gte, lte)
    setValue(`${name}.gte`, gte)
    setValue(`${name}.lte`, lte)
  }

  const selected = hasSameDate(gte, value?.gte) && hasSameDate(lte, value?.lte)

  return (
    <Chip
      label={label}
      color={selected ? 'info' : 'default'}
      onClick={handleClick}
    />
  )
}

export default AutoSearchDateRange
