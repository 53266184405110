import { Close, InfoOutlined } from '@mui/icons-material'
import { Box, Card, IconButton, Popover, Typography } from '@mui/material'
import React from 'react'

const ConsistentPopover = ({
  children,
  title,
  description,
  sx,
  icon = <InfoOutlined />,
  color = 'primary',
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = e => setAnchorEl(e.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      {children || (
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          sx={{ p: 0, ...sx }}
          color={color}
          {...rest}
        >
          {icon}
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
      >
        <Card
          sx={{
            p: 2,
            maxWidth: 310,
            borderRadius: 0.5,
            border: '1px solid #ececec',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            {icon}

            <Typography variant="h6" sx={{ ml: 1, flexGrow: 1 }}>
              {title}
            </Typography>
            <IconButton sx={{ p: 0.5, ml: 2, mr: -0.5, mt: -0.5 }}>
              <Close onClick={handleClose} />
            </IconButton>
          </Box>
          {description && <Typography>{description}</Typography>}
        </Card>
      </Popover>
    </>
  )
}

export default ConsistentPopover
