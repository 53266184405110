import { createClient } from '@sanity/client'
// import client from '~/client'
import isDev from '~/config/isDev'

const sanityOptions = {
  projectId: 'pgen7vbm',
  dataset: isDev() ? 'development' : 'production',
  apiVersion: '2022-03-25', // use current UTC date - see "specifying API version"!
  useCdn: !isDev(),
}
const sanityClient = createClient(sanityOptions)

export const sanityAuthClient = createClient({
  ...sanityOptions,
})

export default sanityClient
