import { styled } from '@mui/material/styles'

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

export default ActionButtons
