import { useFormContext } from 'react-hook-form-mui'
import client from '../../../client'
import { companyTypeOptions } from '../../Companies/companyTypes'
import AutoSearchMultiCheck from './AutoSearchMultiCheck'
import AutoSearchTextField from './AutoSearchTextField'
import AutoSearchDateRange from './AutoSearchDateRange'
import isDev from '../../../config/isDev'

import {
  userRoleOptions,
  userStatusOptions,
  companyStatusOptions,
  proposalStatusOptions,
  estimateStatusOptions,
} from './statusOptions'

const AutoTableSearchColumns = props => {
  const { name, entityType } = props
  // entityType not used
  const { watch, setValue, setFocus } = useFormContext()
  const value = watch(name)

  if (typeof name !== 'string') return null
  if (
    [
      'firstName',
      'lastName',
      'email',
      'company.companyName',
      'distributor.companyName',
      'contractingFirm.companyName',
      'companyName',
      'customerEmail',
      'name',
      'description',
      'sku',
    ].includes(name)
  )
    return <AutoSearchTextField name={name} />

  if (name === 'status')
    return (
      <AutoSearchMultiCheck
        name={name}
        options={
          entityType === 'company'
            ? companyStatusOptions
            : entityType === 'estimate'
            ? estimateStatusOptions
            : userStatusOptions
        }
      />
    )

  if (name === 'role')
    return <AutoSearchMultiCheck name={name} options={userRoleOptions} />

  if (name === 'companyType')
    return <AutoSearchMultiCheck name={name} options={companyTypeOptions} />

  if (
    [
      'createdAt',
      'sentDate',
      'updatedAt',
      'inviteSentAt',
      'inviteAcceptedAt',
      'lastLoginAt',
      'dateRead',
    ].includes(name)
  )
    return <AutoSearchDateRange name={name} />

  // if (isDev())
  //   return (
  //     <AutoSearchTextField
  //       name={name}
  //       slotProps={{ iconButton: { sx: { color: '#f800ff' } } }}
  //     />
  //   )

  return null
}

export default AutoTableSearchColumns
