export const camelToSentence = value =>
  typeof value !== 'string'
    ? value
    : value?.length < 4
    ? value.toUpperCase()
    : value
        ?.split(/([A-Z]|\d)/)
        .map((v, i, arr) => {
          // If first block then capitalise 1st letter regardless
          if (!i) return v.charAt(0).toUpperCase() + v.slice(1)
          // Skip empty blocks
          if (!v) return v
          // Underscore substitution
          if (v === '_') return ' '
          // We have a capital or number
          if (v.length === 1 && v === v.toUpperCase()) {
            const previousCapital = !arr[i - 1] || arr[i - 1] === '_'
            const nextWord =
              i + 1 < arr.length && arr[i + 1] && arr[i + 1] !== '_'
            const nextTwoCapitalsOrEndOfString =
              i + 3 > arr.length || (!arr[i + 1] && !arr[i + 3])
            // Insert space
            if (!previousCapital || nextWord) v = ' ' + v
            // Start of word or single letter word
            if (nextWord || (!previousCapital && !nextTwoCapitalsOrEndOfString))
              v = v.toLowerCase()
          }
          return v
        })
        .join('')
        .replace(/URL|url|Url/, 'URL')
        .replace(' id', ' ID')

export const pluralise = (label, count) =>
  typeof label === 'string' && typeof count === 'number'
    ? `${count} ${label}${count !== 1 ? 's' : ''}`
    : label

export const camelToTitle = camel =>
  camel
    ?.replace?.(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim()
