import { Card, LinearProgress, Typography } from '@mui/material'

import useCustomPrices from '~/routes/Pricing/BranchPricing/useCustomPrices'
import useSanityProductCount from '~/routes/Pricing/BranchPricing/useSanityProductCount'
import sharedProps from '~/components/sharedProps'

export default function ProductCounter() {
  const { data: total, isValidating } = useSanityProductCount()

  const {
    data: customProducts,
    // saveCustomPrice,
    // isLoading: isLoadingCustom,
  } = useCustomPrices()

  const completed = customProducts?.length

  const percentage = (completed / total) * 100

  const loaded = !isNaN(percentage)

  const value = loaded ? percentage : 0

  // const variant = isNaN(percentage) ? 'query' : 'determinate'
  const variant = 'determinate'

  const remainder = total - completed

  const color =
    completed === 0
      ? 'error'
      : percentage < 100
      ? 'warning'
      : remainder === 0
      ? 'success'
      : 'inherit'

  const text = !loaded
    ? 'Loading...'
    : completed === 0
    ? `You'ven't added any custom prices yet, ${total || 'hundreds'} to go!`
    : remainder > 0
    ? `${completed} out of ${total} custom prices added`
    : `All ${total} products have custom prices`

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Typography sx={{ mb: 1 }}>{text}</Typography>
      <LinearProgress
        {...{ value, variant, color }}
        sx={{ opacity: loaded ? 1 : 0, transition: 'opacity .3s' }}
      />
    </Card>
  )
}
