import { Stack, Typography } from '@mui/material'
import * as React from 'react'

import { PostAdd, Save } from '@mui/icons-material'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router'
import SubmitButton from '~/components/Data/SubmitButton'

import useTextTemplates from '~/routes/Proposals/TextTemplates/useTextTemplates'
import LoadingIndicators from '~/components/Data/LoadingIndicators'

const initTemplate = { contents: '', name: '' }

const EditTextTemplateForm = () => {
  const location = useLocation()
  const template = location.state?.data

  const formContext = useForm({
    defaultValues: template || initTemplate,
  })

  React.useEffect(() => {
    if (template) formContext.reset(template)
  }, [template])

  const isNew = !template
  const title = isNew ? 'Add new' : 'Edit'
  // const cta = isNew ? 'Add new' : 'Save'
  const cta = 'Save'

  const { isSubmitting, saveTemplate } = useTextTemplates()

  const navigate = useNavigate()
  const onSuccess = async data => {
    await saveTemplate(data)
    navigate(-1)
  }

  return (
    <FormContainer {...{ formContext, onSuccess }}>
      <Stack spacing={3} sx={{ alignItems: 'flex-start' }}>
        <Typography variant="h3">{title} message template</Typography>
        <TextFieldElement
          name="name"
          required
          label="Name"
          sx={{ flexGrow: 1 }}
          fullWidth
        />
        <TextFieldElement
          fullWidth
          name="contents"
          required
          label="Contents"
          multiline
          minRows={5}
          // sx={{ flexGrow: 1, width: { xs: '100%', sm: 'auto' } }}
        />
        <LoadingIndicators isSubmitting={isSubmitting} />
        <SubmitButton
          // disabled={
          //   !formContext.formState.isDirty && template?.proposalTemplateId
          // }
          // endIcon={<SubmittingIcon active={isSubmitting} />}
          // onClick={handleSaveClick}
          isSubmitting={isSubmitting}
          endIcon={isNew ? <PostAdd /> : <Save />}
        >
          {cta} message template
        </SubmitButton>
      </Stack>
    </FormContainer>
  )
}

export default EditTextTemplateForm
