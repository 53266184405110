import Button from '@mui/material/Button'
import React from 'react'
import FanIcon from './FanIcon'
import SubmittingIcon from './SubmittingIcon'
import sharedProps from '../sharedProps'

const SubmitButton = React.forwardRef(
  ({ isSubmitting, endIcon, sx, ...props }, ref) => {
    return (
      <Button
        type="submit"
        variant="contained"
        children="Save changes"
        // disabled={isSubmitting}
        // inputProps
        sx={{
          ...sx,
          ...(endIcon && {
            '.MuiButton-endIcon': {
              mr: '-4px !important',
            },
          }),
        }}
        {...props}
        ref={ref}
        endIcon={
          endIcon ? (
            isSubmitting ? (
              <SubmittingIcon isSubmitting />
            ) : (
              endIcon
            )
          ) : (
            <SubmittingIcon isSubmitting={isSubmitting} />
          )
        }
      />
    )
  }
)

export default SubmitButton
