import { Close, InfoOutlined } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import { FormContainer } from 'react-hook-form-mui'

export default function ConsistentDialog({
  children,
  onClose,
  title,
  icon = <InfoOutlined />,
  ...rest
}) {
  return (
    <Dialog maxWidth="sm" onClose={onClose} scroll="paper" {...rest}>
      <DialogTitle
        variant="h4"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '& > svg': { mr: 1 },
        }}
      >
        {icon}
        {title}
        <IconButton
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {React.Children.toArray(children).some(child =>
        [DialogContent, FormContainer].includes(child.type)
      ) ? (
        children
      ) : (
        <DialogContent>{children}</DialogContent>
      )}
    </Dialog>
  )
}
