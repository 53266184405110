import { Link as MuiLink } from '@mui/material'

export default function LinkToModelNumber({
  modelNumber,
  children,
  noWrap,
  ...rest
}) {
  return (
    <MuiLink
      href={`/app/product-catalogue/?modelNumber=${modelNumber}`}
      target="_blank"
      {...rest}
    >
      {children || modelNumber.replace(/-/g, '‑')}
    </MuiLink>
  )
}
