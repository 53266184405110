import { Box, Paper, Tab, Tabs } from '@mui/material'
import { NavLink, Outlet, useMatches } from 'react-router'
import useSiblings from '../useSiblings'

const useTabValue = (depth = 2) => {
  const matches = useMatches()
  const path = matches[depth + 1]?.pathname.replace(matches[depth].pathname, '')
  return path?.replace('/', '')
}

export default function LayoutSalesAppTabs() {
  const siblings = useSiblings({ maxDepth: 2, nav: true })
  const tabValue = useTabValue(2)

  return (
    <>
      {/* <MultiPortal name="title">{title}</MultiPortal> */}
      <Box
        sx={{
          // order: -1,
          mb: 2,
          display: 'flex',
          // justifyContent: 'stretch',
          // alignItems: 'stretch',
          gap: 2,
          maxWidth: '100%',
        }}
      >
        {/* <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            aspectRatio: '1',
            height: '100%',
          }}
        >
          <Tab
            sx={{
              p: 0,
              minWidth: 0,
              pl: 1,
              // bgcolor: 'secondary.main',
              color: 'secondary.main',
              opacity: 1,
            }}
            value="back"
            icon={<ArrowBackIos sx={{ color: 'inherit' }} />}
          />
        </Paper> */}
        <Paper sx={{ maxWidth: '100%', display: 'flex' }}>
          <Tabs value={tabValue} variant="scrollable" scrollButtons>
            {siblings?.map(route => (
              // <NavLink to={route.path.replace('/*', '')} key={route.path}>
              // {({ isActive }) => (
              <Tab
                // sx={{ color: isActive ? 'primary.main' : 'inherit' }}
                sx={{
                  // wordBreak: 'keep-all',
                  whiteSpace: 'nowrap',
                  // borderBottom: 2,
                  // ...(isActive
                  //   ? { color: 'primary.main', opacity: 1 }
                  //   : { borderColor: 'transparent' }),
                }}
                label={route.handle?.title}
                key={route.path}
                value={route.path}
                component={NavLink}
                to={route.path.replace('/*', '')}
                viewTransition
                // value={isActive ? 'active' : route.path}
                // variant="h4"
                // component={Typography}
              />
              // )}
              // </NavLink>
            ))}
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </>
  )
}
