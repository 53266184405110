import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Stack } from '@mui/material'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import Link from '~/config/Link'
import { useParam } from '~/core/router/useParams'
import CompaniesTable from '~/components/Companies/CompaniesTable'
import EditCompanyForm from '~/components/Companies/EditCompanyForm'
import AddCompanyForm from '~/components/Companies/AddCompanyForm'
import { sxAppStylesWindow } from '~/components/Layouts/AppStyles'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import { DomainAdd } from '@mui/icons-material'

export default function SuperadminCompanies({}) {
  return (
    <>
      <ActionButtons>
        <Link to="?add">
          <Button variant="contained" endIcon={<DomainAdd />}>
            Add new company
          </Button>
        </Link>
      </ActionButtons>

      <Card sx={{ position: 'relative', ...sxAppStylesWindow }}>
        <CompaniesTable />
      </Card>

      <Drawer open={useParam('add')}>
        <AddCompanyForm />
      </Drawer>

      <Drawer open={useParam('edit')}>
        <EditCompanyForm />
      </Drawer>
    </>
  )
}
