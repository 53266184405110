import { enqueueSnackbar } from 'notistack'
import React from 'react'
import useSWR from 'swr'
import client from '~/client'
import { getFileExtension } from '~/routes/Company/Assets/AssetPreview'
import fetchProgress from '~/routes/Company/Assets/fetchProgress'
import usePresignedUpload from '~/routes/Company/Profile/usePresignedUpload'

export default function useCompanyDetails({ companyId: propsCompanyId } = {}) {
  const userInfo = client.getUserInfo()

  const companyId = propsCompanyId || userInfo?.companyId

  const { data, mutate, ...rest } = useSWR(
    `company/?companyId=${companyId}`,
    client.fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  // const {upload, isUploading} = usePresignedUpload()

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const updateCompany = async fields => {
    try {
      setIsSubmitting(true)

      const numberFields = ['markupRate', 'expiryTime']
      numberFields.forEach(field => {
        if (fields[field] === '') fields[field] = null
      })

      await mutate(client.put('companyDetails', { ...fields, companyId }), {
        throwOnError: true,
        optimisticData: data => ({ ...data, ...fields }),
      })

      enqueueSnackbar(
        `${fields.companyName || data.companyName || 'Company'} updated`,
        { variant: 'info' }
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const [isUploadingLogo, setIsUploading] = React.useState(false)

  const uploadCompanyLogo = async data => {
    try {
      setIsUploading(1)

      const file = data.files?.[0] || data
      // const id = uuid()
      const ext = getFileExtension(file.name)
      // const filename = `${id}${ext}`
      const filename = `company-logo${ext}`

      const presignedUrl = await client.get(
        `presigned?type=company-logo&filename=${filename}`
      )

      // TODO: upload progress, XHR instead of fetch
      const res = await fetchProgress(presignedUrl, {
        method: 'PUT',
        body: file,
        'Content-Type': file.type,
        onProgress: e => setIsUploading(e * 0.95),
      })

      console.log('resssssses', { presignedUrl, res })
      // const url = new URL(res.url)
      await mutate()

      // we don't actually have the asset yet so work out the filename from s3 res
      // const asset = {
      //   url: url.pathname.replace('/', ''),
      //   meta: { ...data.meta, ContentType: file.type },
      // }

      // await mutate(client.put('assets', asset), {
      //   populateCache: false,
      // })
    } catch (e) {
      console.error(e)
      // // enqueueSnackbar(e || 'Error', { variant: 'error' })
    } finally {
      setIsUploading(false)
    }
  }

  // const deleteExclusionsTemplateFile = async () => updateCompany({ exclusionsTemplateFile: 'reset' })

  const deleteCompany = async ({ companyId, companyName }) => {
    await client.delete('companyDetails', { companyId })
  }

  return {
    ...rest,
    data,
    mutate,
    isSubmitting,
    onSuccess: updateCompany,
    deleteCompany,
    updateCompany,
    isUploading: isUploadingLogo,
    uploadCompanyLogo,
    // deleteExclusionsTemplateFile,
    // patchCompany,
  }
}
