import { Card } from '@mui/material'
import ExistingSystem from './ExistingSystem'
import ReplacementSystem from './ReplacementSystem'
import Summary from './Summary'
import DesignCheck from './DesignCheck'
import Complete from './Complete'

const steps = [
  {
    name: 'existing-system',
    label: 'Existing system',
    Component: ExistingSystem,
  },
  {
    name: 'replacement-system',
    label: 'Replacement system',
    Component: ReplacementSystem,
  },
  {
    name: 'replacement-summary',
    label: 'Replacement summary',
    Component: Summary,
  },
  {
    name: 'design-check',
    label: 'Design check',
    Component: DesignCheck,
  },
  {
    name: 'complete',
    label: 'Complete',
    Component: Complete,
  },
]

export default steps
