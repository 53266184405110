import { Box, Card, Typography } from '@mui/material'
import Chip from '~/components/Data/Chip'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sxFlexColumnsCard from '~/core/sx/sxFlexColumnsCard'
import { formatDate } from '~/core/utils/formatDate'
import { usd } from '~/core/utils/formatNumber'
import sxCardInfo from '~/routes/Proposals/Overview/Summary/sxCardInfo'
import addressToArray from '~/routes/ViewProposal/addressToArray'

const OverviewHeader = ({ data = {}, isLoading, sx }) => {
  const customer = data.contents?.customer || {}
  const contractor = data.contents?.contractor || {}
  return (
    <Card sx={{ ...sxCardInfo, ...sx }}>
      <LoadingIndicators isLoading={isLoading} />
      <Typography variant="h4">Overview</Typography>
      <div>
        <div>
          <Typography variant="h6">Homeowner details</Typography>
          <div>{customer.name}</div>
          <div>{customer.email}</div>
          <div>{customer.phoneNumber}</div>
        </div>
        <div>
          <Typography variant="h6" sx={{ visibility: 'hidden' }}>
            Homeowner address
          </Typography>
          {addressToArray(customer.address).map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
        <div>
          <Typography variant="h6">Created date</Typography>
          {formatDate(data.createdAt)}
        </div>
        <div>
          <Typography variant="h6">Last updated</Typography>
          {formatDate(data.updatedAt)}
        </div>
        <div>
          <Typography variant="h6">Status</Typography>
          <Chip label={data.status} />
        </div>
        <div>
          <Typography variant="h6">Contractor</Typography>
          <div>
            {[contractor?.firstName, contractor?.lastName]
              .filter(Boolean)
              .join(' ')}
          </div>
          <div>{contractor.email}</div>
        </div>
      </div>
      <Box
        sx={{
          ...sxFlexColumnsCard,
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant="h6">Proposal ID</Typography>
          {data.proposalId}
        </div>
        <Typography variant="h6">
          Total: {usd.format(data.contents?.totalPrice)}
        </Typography>
        {/* {usd.format(data.contents.totalPrice)} */}
      </Box>
    </Card>
  )
}
export default OverviewHeader
