import { Box, Card, Container, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useNavigate } from 'react-router'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import { cognitoPassword } from '~/core/forms/validation'
import PasswordFeedback, { testPassword } from '~/routes/Auth/PasswordFeedback'

const defaultValues = {
  password: '',
  confirmPassword: '',
}

export default function ResetPassword() {
  const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    if (!window.location.search) return

    try {
      const searchParams = new URLSearchParams(window.location.search)

      formContext.setValue('username', searchParams.get('username'))
      formContext.setValue(
        'confirmationCode',
        searchParams.get('confirmationCode')
      )
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    }
  }, [])

  const handleClick = async data => {
    try {
      if (data.password !== data.confirmPassword) {
        enqueueSnackbar('Passwords must match', { variant: 'error' })
        return
      }

      setSubmitting(true)
      const res = await client.forgotPassword({
        username: data.username,
        password: data.password,
        confirmationCode: data.confirmationCode,
      })

      console.log('UpdatePassword res', res)

      if (client.isSignedIn()) {
        setTimeout(() => navigate('/app'))
      } else {
        console.error(res)
        throw (
          res.message?.message || 'UpdatePassword.jsx: error updating password'
        )
      }
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const formContext = useForm({ defaultValues })
  // const { watch } = formContext

  // const password = watch('password')
  // const passwordFeedback = testPassword(password)
  // const isValid = passwordFeedback.every(({ valid }) => !!valid)

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
        <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
          Reset your password
        </Typography>
      </Box>
      <Card sx={{ p: 3, py: 4, position: 'relative' }}>
        <FormContainer onSuccess={handleClick} formContext={formContext}>
          <TextFieldElement
            fullWidth
            name="username"
            id="username"
            label="Email"
            required
            sx={{ mb: 3 }}
            autoComplete="username"
            type="email"
          />

          <TextFieldElement
            fullWidth
            name="confirmationCode"
            id="confirmationCode"
            label="Confirmation Code"
            required
            sx={{ mb: 3 }}
          />

          <PasswordElement
            fullWidth
            id="password"
            name="password"
            label="Password"
            required
            rules={{ pattern: cognitoPassword }}
            sx={{ mb: 3 }}
            autoComplete="new-password"
          />

          <PasswordFeedback />

          <PasswordRepeatElement
            passwordFieldName="password"
            required
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm password"
            sx={{ mb: 3 }}
            autoComplete="new-password"
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SubmitButton
              isSubmitting={isSubmitting}
              sx={{ flexShrink: 0, alignSelf: 'start' }}
            >
              Update password
            </SubmitButton>
          </Box>
        </FormContainer>
        <LoadingIndicators isSubmitting={isSubmitting} />
      </Card>
    </Container>
  )
}
