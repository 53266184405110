import React from 'react'
import { useFormContext } from 'react-hook-form-mui'

const useAutoDeselect = ({ name, options = [] }) => {
  const { setValue, getValues, formState } = useFormContext()

  const previousOption = React.useRef()
  // const value = watch(name)

  React.useEffect(() => {
    const value = getValues(name)
    const hasUserInteracted = formState?.touchedFields[name]

    const selectedOption = options.find(option => option.id === value)
    const validOptions = options.filter(option => !option.disabled)

    if (selectedOption?.disabled === true) {
      previousOption.current = selectedOption
      if (validOptions.length === 1) setValue(name, validOptions[0].id)
      else setValue(name, '')
    }
    if (
      validOptions.some(option => option.id === previousOption.current?.id) &&
      !hasUserInteracted
    )
      setValue(name, previousOption.current?.id)
  }, [options])
}

export default useAutoDeselect
