import { PersonAdd } from '@mui/icons-material'
import { Button, Card } from '@mui/material'
import { useNavigate } from 'react-router'
import client from '~/client'
import { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import Drawer from '~/components/Layouts/Drawer'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import AddNewUser from '~/components/Users/AddNewUser'
import DeleteUser from '~/components/Users/DeleteUser'
import EditUser from '~/components/Users/EditUser'
import UsersTable from '~/components/Users/UsersTable'
import useSearchParams, { useParam } from '~/core/router/useParams'

export default function DistributorUsers() {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <>
      {client.hasRoles([
        'rep_admin',
        'rep_sales_manager',
        'distributor_company_user',
        'distributor_company_admin',
      ]) && (
        <ActionButtons>
          <Button
            onClick={() =>
              navigate(`?addUser`, { state: { user: { companyId: '' } } })
            }
            endIcon={<PersonAdd />}
          >
            Add user
          </Button>
        </ActionButtons>
      )}
      <Card sx={sxAppStylesFlex}>
        <UsersTable
          appStyles
          disableEdit={client.hasOnlyRoles('distributor_company_user')}
          query="distributorCompanyRelations/users"
          onEditClick={user =>
            navigate(`?editUser=${user.userId}`, { state: user })
          }
          onDeleteClick={user =>
            navigate(`?deleteUser=${user.userId}`, { state: user })
          }
        />
      </Card>
      <Drawer open={params.hasOwnProperty('addUser')}>
        <AddNewUser companyType="distributor" />
      </Drawer>
      <Drawer open={params.editUser}>
        <EditUser />
      </Drawer>
      <Drawer open={useParam('delete')}>
        <DeleteUser />
      </Drawer>
    </>
  )
}
