import { CssBaseline, ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'
// import { RouterProvider, createBrowserRouter } from 'react-router/dom'
import { createBrowserRouter } from 'react-router'
import { RouterProvider } from 'react-router/dom'
import '~/config/Sentry'
import theme from '~/config/theme/theme'
import { GlobalProvider } from '~/core/state/GlobalStateProvider'
import routes from './routes'
// import Zendesk from '~/core/tracking/Zendesk'
// import Google from '~/core/tracking/Google'

import { useLocalStorage } from 'usehooks-ts'

const router = createBrowserRouter(routes)

function App() {
  const [token] = useLocalStorage('auth')
  const rerenderKey = `${token?.userInfo?.email}-${token?.userInfo?.role}`
  // https://usehooks-ts.com/react-hook/use-local-storage
  // console.log('rerenderKey', rerenderKey, token)
  return (
    <ThemeProvider theme={theme}>
      <Helmet
        defaultTitle="Unico Sales App"
        titleTemplate="Unico Sales App - %s"
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap"
          rel="stylesheet"
        />
        <meta name="theme-color" content="#4285f4" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-RF1SGSQ5SM"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-RF1SGSQ5SM');
          `}
        </script>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=ba8e5993-37bb-45de-b4de-13e49a2f036e"
        />
      </Helmet>
      <SnackbarProvider variant="info" preventDuplicate>
        <GlobalProvider>
          <CssBaseline />
          <RouterProvider router={router} key={rerenderKey} />
        </GlobalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
