import { Card, Typography } from '@mui/material'
import AutoTable from '~/components/Data/AutoTable/AutoTable'
import useSearchParams from '~/core/router/useParams'

export default function ProductList({ sx, data, title, ...rest }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigateProduct = product =>
    setSearchParams(({ modelNumber, ...p }) =>
      product?.modelNumber ? { ...p, modelNumber: product.modelNumber } : p
    )

  if (!data?.length) return null

  return (
    <Card sx={{ p: 2, mb: 2, ...sx }}>
      {title && (
        <Typography variant="h4" gutterBottom color="grey.600">
          {title}
        </Typography>
      )}
      <AutoTable
        appStyles
        data={data}
        columns={['modelNumber', 'title']}
        onRowClick={navigateProduct}
        {...rest}
      />
    </Card>
  )
}
