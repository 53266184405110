import { Card, Typography } from '@mui/material'
import { camelToSentence } from '~/core/utils/formatString'
import sxCardInfo from '~/routes/Proposals/Overview/Summary/sxCardInfo'

export default function ProjectDetails({ data, sx, ...rest }) {
  if (!data?.contents?.details) return null
  return (
    <Card sx={{ ...sxCardInfo, ...sx }} {...rest}>
      <Typography variant="h4">Project details</Typography>
      <div>
        {Object.entries(data.contents.details)
          .filter(([key, value]) => typeof value === 'string')
          .map(([key, value]) => (
            <div>
              <Typography key={key} variant="h6">
                {camelToSentence(key)}
              </Typography>
              {camelToSentence(value)}
            </div>
          ))}
      </div>
      {data.contents.details.notes && (
        <div>
          <Typography variant="h6">Notes</Typography>
          {data.contents.details.notes}
        </div>
      )}
    </Card>
  )
}
