import defaultClient from '~/client/sanity'
import React from 'react'

const useWeirdSanityQuery = (query, params = {}, client = defaultClient) => {
  const [data, setData] = React.useState([])
  const [status, setStatus] = React.useState('loading')
  // console.log('FETCH status ', status)

  const getData = async () => {
    // console.log('FETCH for', query, params)
    setStatus('loading')
    client
      .fetch(query, params)
      .then(props => {
        setData(props)
        setStatus('loaded')
      })
      .catch(e => setStatus('error'))
  }

  React.useEffect(() => {
    if (Object.entries(params).every(([k, v]) => typeof v !== 'undefined'))
      getData()
    else if (Object.entries(params).some(([k, v]) => typeof v !== 'undefined'))
      setStatus('error')
    // else console.log('FETCH params not yet loaded')
  }, [...Object.values(params), query])

  return [data, getData, status]
}

export default useWeirdSanityQuery
