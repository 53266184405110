import {
  // Chip,
  Dialog,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import SubmitButton from '~/components/Data/SubmitButton'

import { Close, Delete, Send } from '@mui/icons-material'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'

import Block from '@mui/icons-material/Block'
import Chip, { systemToHuman } from '~/components/Data/Chip'
import { enqueueSnackbar } from 'notistack'

import {
  reasonOptionsContractor,
  statusElements,
  statusOptions,
  statusElementsArray,
  getValidStatuses,
  canUpdate,
} from '~/routes/Proposals/statusUtils'

export default function ProposalStatusDialog({
  proposal,
  open = proposal || false,
  onClose,
  newStatus = proposal?.newStatus || '',
  updateStatus,
  ...rest
}) {
  // const { updateStatus, isSubmitting } = {}

  const [statusReasons, setStatusReasons] = React.useState([])
  const [message, setMessage] = React.useState('')
  const [status, setStatus] = React.useState(newStatus)

  React.useEffect(() => {
    if (proposal?.newStatus) setStatus(proposal.newStatus)
  }, [proposal?.newStatus])

  const toggleStatusReason = reason =>
    setStatusReasons(reasons =>
      reasons.includes(reason)
        ? reasons.filter(r => r !== reason)
        : [...reasons, reason]
    )

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const handleUpdateClick = async () => {
    try {
      setIsSubmitting(true)
      const payload = { proposalId: proposal.proposalId, status }

      if (status === 'contractor_rejected')
        payload.statusReasons = statusReasons.map(reason => ({
          reason,
          ...(reason === 'other' && { message }),
        }))

      if (status === 'cancelled')
        payload.statusReasons = [{ reason: 'cancelled', message }]

      await updateStatus(payload)
      console.log('updateStatus', payload)
      enqueueSnackbar('Proposal status updated', { variant: 'success' })
      onClose()
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 1, width: 360 } }}
    >
      <Stack spacing={2} sx={{ m: 2 }}>
        <Typography variant="h4">Update proposal status</Typography>
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'space-between' }}
        >
          <Stack spacing={1}>
            <div>Current status</div>
            <Chip label={proposal?.status} />
          </Stack>
          <TextField
            select
            label="Update to"
            value={status}
            onChange={e => setStatus(e.target.value)}
            renderValue={systemToHuman}
            InputProps={{ sx: { minWidth: 160 } }}
            required
          >
            {statusOptions.map(({ id, label }) => (
              <MenuItem
                key={id}
                value={id}
                sx={{ '* > div': { textTransform: 'capitalize' } }}
                // disabled={!status.includes('contractor')}
              >
                {label || systemToHuman(id)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {/* <CheckboxButtonGroup
            sx={{}}
            // showChips
            name="statusReasons"
            options={statusOptions}
          /> */}
        {status === 'contractor_rejected' && (
          <Stack spacing={2} {...sharedProps.motion.collapse}>
            <Divider />
            <Typography variant="h4" gutterBottom>
              Rejected reasons
            </Typography>
            <div>
              {reasonOptionsContractor.map(({ id, label }) => (
                <Chip
                  key={id}
                  sx={{ m: 0.5 }}
                  onClick={() => toggleStatusReason(id)}
                  label={label}
                  variant={statusReasons.includes(id) ? 'filled' : 'outlined'}
                  color={statusReasons.includes(id) ? 'primary' : 'default'}
                />
              ))}
            </div>
          </Stack>
        )}
        <Divider />
        {((status.includes('rejected') && statusReasons.includes('other')) ||
          status === 'cancelled') && (
          <Stack spacing={2} {...sharedProps.motion.collapse}>
            <Typography variant="h4">Reason</Typography>
            <TextField
              rows={3}
              multiline
              onChange={e => setMessage(e.target.value)}
              placeholder="Please provide a reason"
              required
              value={message}
            />
            <Divider />
          </Stack>
        )}
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'space-between' }}
        >
          <SubmitButton
            variant="contained"
            color={statusElements[status]?.color}
            onClick={handleUpdateClick}
            endIcon={statusElements[status]?.icon}
            {...{ isSubmitting }}
            disabled={!canUpdate({ status, statusReasons, message })}
          >
            {statusElements[status]?.verb || 'Update'} proposal
          </SubmitButton>
          <Button
            color="inherit"
            variant="text"
            endIcon={<Close />}
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
        <LoadingIndicators {...{ isSubmitting }} />
      </Stack>
    </Dialog>
  )
}
