import {
  Button,
  Card,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'

import { Send } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import {
  FormContainer,
  MultiSelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { useSWRConfig } from 'swr'
import client from '~/client'
import SubmitButton from '~/components/Data/SubmitButton'
import sharedProps from '~/components/sharedProps'
import useSystemRoles from '~/components/Users/useSystemRoles'

const defaultNotification = {
  to_username: null,
  to_role: null,
  to_team: null,
  // subject: '',
  // body: '',
  dateRead: null,
}
export default function SendNotification() {
  const formContext = useForm()

  const [status, setStatus] = React.useState(null)

  const { mutate } = useSWRConfig()

  const onSuccess = async data => {
    const notification = {
      ...defaultNotification,
      ...data,
      to_role: data.to_role.join(','),
      ...(data.to_role.includes('to_all') && {
        to_all: true,
        to_role: null,
      }),
    }

    console.log('data notification', data, notification)

    try {
      setStatus('submitting')
      const res = await client.put('notification', notification)
      formContext.reset()
      setStatus('success')
      mutate('notification')
      enqueueSnackbar('Notification sent')
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setStatus(null)
    }
  }

  const rolesOptions = [{ id: 'to_all', label: 'Global' }, ...useSystemRoles()]

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={6}>
          <Card {...sharedProps.card}>
            {status === 'success' ? (
              <Stack>
                <Typography variant="h3">
                  Your notification has been sent
                </Typography>
                <Button onClick={() => setStatus(null)}>Send another</Button>
              </Stack>
            ) : (
              <FormContainer formContext={formContext} onSuccess={onSuccess}>
                <Stack spacing={3} sx={{ alignItems: 'flex-start' }}>
                  <MultiSelectElement
                    fullWidth
                    name="to_role"
                    label="Send to"
                    showCheckbox
                    // showChips
                    // required
                    // sx={{ mb: 3 }}
                    options={rolesOptions}
                    renderValue={values =>
                      values
                        .map(
                          value =>
                            rolesOptions?.find(option => option.id === value)
                              ?.label
                        )
                        .join(', ')
                    }
                  />
                  <TextFieldElement
                    required
                    fullWidth
                    name="subject"
                    label="Subject"
                    type="text"
                    disabled={status === 'submitting'}
                  />
                  <TextFieldElement
                    required
                    fullWidth
                    name="body"
                    label="Body"
                    type="text"
                    multiline
                    rows={5}
                    disabled={status === 'submitting'}
                  />
                  <SubmitButton
                    color="success"
                    isSubmitting={status === 'submitting'}
                    endIcon={<Send />}
                  >
                    Send notification
                  </SubmitButton>
                </Stack>
              </FormContainer>
            )}
            {status === 'submitting' && (
              <LinearProgress {...sharedProps.linearProgress.bottom} />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
