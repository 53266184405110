import {
  Alert,
  AlertTitle,
  Card,
  Divider,
  Link,
  Typography,
} from '@mui/material'
import cardSx from '~/core/sx/cardSx'
import sxFlexStack from '~/core/sx/sxFlexStack'
import StepButtons from './StepButtons'
import { FormContainer, ToggleButtonGroupElement } from 'react-hook-form-mui'
import { findOptionsMap } from '~/core/sanity/findOptions'
import booleanToggleButtonGroupProps from '~/core/forms/booleanToggleButtonGroupProps'
import { Link as RouterLink } from 'react-router'

const toggleProps = {
  enforceAtLeastOneSelected: true,
  exclusive: true,
  required: true,
  color: 'primary',
}

export default function ExistingSystem({ onNextClick, onPrevClick, ...rest }) {
  return (
    <>
      <Card sx={{ ...cardSx, ...sxFlexStack, gap: 2 }}>
        <Typography variant="h4">Replace existing equipment guide</Typography>
        <Typography>
          This tool will help you find new, compatible equipment to replace
          existing <b>blowers and coils</b>.
        </Typography>
        <Alert severity="info" variant="outlined">
          <AlertTitle>Trying to create a new system?</AlertTitle>
          <Link component={RouterLink} to="/app/proposals/fast-quote">
            FastQuote
          </Link>{' '}
          allows you to create a new system from scratch, creating full material
          lists.
        </Alert>
      </Card>
      <FormContainer {...rest}>
        <Card sx={{ ...cardSx, ...sxFlexStack, gap: 2 }}>
          <Typography variant="h4">Existing system</Typography>
          <Typography>
            Please provide information about the existing installation.
          </Typography>

          <Divider />

          <ToggleButtonGroupElement
            name="existing.manufacturer"
            label="Existing equipment manufacturer"
            options={['Unico', 'SpacePak', 'Hi-V', 'Other'].map(value => ({
              value,
              label: value,
            }))}
            {...toggleProps}
          />

          <ToggleButtonGroupElement
            name="existing.tonnage"
            label="Existing outdoor tonnage"
            options={findOptionsMap(['products', 'spec', 'tonnage'])?.slice(1)}
            {...toggleProps}
          />

          <ToggleButtonGroupElement
            name="existing.orientation"
            label="System orientation"
            options={findOptionsMap([
              'products',
              'spec',
              'orientation',
            ])?.filter(({ id }) => id !== 'verticalUpflowSingle')}
            {...toggleProps}
          />

          <ToggleButtonGroupElement
            name="existing.direction"
            label="System direction"
            options={findOptionsMap(['products', 'spec', 'direction'])}
            {...toggleProps}
          />

          <ToggleButtonGroupElement
            label="Does the existing return adapter need replacing?"
            name="existing.replaceReturnBox"
            {...booleanToggleButtonGroupProps}
            color="primary"
          />

          <ToggleButtonGroupElement
            label="Does the system have a heating coil?"
            name="existing.heatingCoil"
            {...booleanToggleButtonGroupProps}
            color="primary"
          />

          {/* <ToggleButtonGroupElement
            label="Does the system have a cooling coil?"
            name="existing.coolingCoil"
            {...booleanToggleButtonGroupProps}
            color="primary"
          /> */}

          <StepButtons />
        </Card>
      </FormContainer>
    </>
  )
}
