import { Box, Grid } from '@mui/material'
import GlobalPercentagePrice from '~/routes/Pricing/BranchPricing/GlobalPercentagePrice'

import PricingTable from '~/routes/Pricing/BranchPricing/PricingTable'
import ProductCounter from '~/routes/Pricing/BranchPricing/ProductCounter'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import AppStyles, { sxAppStylesFlex } from '~/components/Layouts/AppStyles'

export default function DistributorPricing() {
  // const { data: companyDetails } = useCompanyDetails()
  return (
    <>
      <Box
        container
        sx={{
          gap: 2,
          display: { xs: 'block', sm: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <GlobalPercentagePrice />
        <ProductCounter />
      </Box>
      <AppStyles />
      <PricingTable sx={{ ...sxAppStylesFlex, p: 2 }} />
    </>
  )
}
