import { Button, Card } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
// import Table from '@mui/material/Table'
import { PersonAdd } from '@mui/icons-material'
import client from '~/client'
import Drawer from '~/components/Layouts/Drawer'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'
import AddNewUser from '~/components/Users/AddNewUser'
import DeleteUser from '~/components/Users/DeleteUser'
import EditUser from '~/components/Users/EditUser'
import UsersTable from '~/components/Users/UsersTable'
import useSearchParams, { useParam } from '~/core/router/useParams'
import CompanyProfile from '~/routes/Company/Profile'
import GlobalPercentagePrice from '~/routes/Pricing/BranchPricing/GlobalPercentagePrice'
import PricingTable from '~/routes/Pricing/BranchPricing/PricingTable'

export default function DistributorBranch() {
  const { branchId } = useParams()

  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()

  return (
    <>
      {client.hasRoles('distributor_') && (
        <>
          <CompanyProfile companyId={branchId} />
          <GlobalPercentagePrice companyId={branchId} />
          <PricingTable companyId={branchId} sx={{ p: 2, mb: 2 }} />
        </>
      )}

      <ActionButtons>
        <Button
          onClick={() =>
            navigate(`?addUser`, {
              state: {
                company: {
                  companyId: branchId,
                  companyType: 'distributor',
                },
              },
            })
          }
          endIcon={<PersonAdd />}
        >
          Add user
        </Button>
      </ActionButtons>

      <Card
      // sx={sxAppStylesFlex}
      // for !distros where it's the only thing on the page
      >
        <UsersTable
          // disableEdit={client.hasOnlyRoles(['rep_admin', 'rep_sales_manager'])}
          // appStyles
          disableEdit={client.hasOnlyRoles([
            'distributor_company_user', // TEMP PATCH
            'distributor_company_admin', // TEMP PATCH
            'rep_admin',
            'rep_sales_manager',
          ])}
          query={`distributorCompanyRelations/users?distributorId=${branchId}`}
          onEditClick={user =>
            navigate(`?editUser=${user.userId}`, { state: { user } })
          }
        />
      </Card>

      <Drawer open={params.hasOwnProperty('addUser')}>
        <AddNewUser
          companyType="distributor"
          values={{ companyId: branchId }}
        />
      </Drawer>
      <Drawer open={params.editUser}>
        <EditUser />
      </Drawer>
      <Drawer open={useParam('delete')}>
        <DeleteUser />
      </Drawer>
    </>
  )
}
