import sxFlexStack from '~/core/sx/sxFlexStack'
import sxFlexColumnsCard from '~/core/sx/sxFlexColumnsCard'

export default {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  // alignItems: 'flex-start',
  '& > div': sxFlexColumnsCard,
  '& h3, h4, h5, h6': { mb: 1 },
}
