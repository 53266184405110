import { Box, Card, TableCell, Typography } from '@mui/material'
import ConsistentDialog from '~/components/Modals/ConsistentDialog'
import sxFlexStack from '~/core/sx/sxFlexStack'
import sxFlexColumnsCard from '~/core/sx/sxFlexColumnsCard'
import { usd } from '~/core/utils/formatNumber'
import { camelToSentence } from '~/core/utils/formatString'
import sxCardInfo from '~/routes/Proposals/Overview/Summary/sxCardInfo'
import { formatDate } from '~/core/utils/formatDate'
import AutoTable, { DateCell } from '~/components/Data/AutoTable/AutoTable'

export default function Price({ data, sx, ...rest }) {
  if (!data?.contents?.project) return null
  const project = data.contents?.project || {}
  return (
    <Card sx={{ display: 'flex', alignItems: 'start', gap: 4 }}>
      <Box sx={{ ...sxCardInfo }}>
        <Typography variant="h4">Price</Typography>
        <div>
          <div>
            <Typography variant="h6">Total</Typography>
            {usd.format(data.contents?.totalPrice)}
          </div>

          <div>
            <Typography variant="h6">Discounts</Typography>
            {project.discounts?.length
              ? project.discounts.map(({ title, price }) => (
                  <div>
                    {title}: {usd.format(price)}
                  </div>
                ))
              : 'None'}
          </div>
          <div>
            <Typography variant="h6">Payments</Typography>
            {project.paymentBreakdown?.length}
          </div>
        </div>
      </Box>
      {project.paymentBreakdown?.length && (
        <Box sx={{ ...sxCardInfo }}>
          <Typography variant="h4">Payment breakdown</Typography>
          <div>
            <AutoTable
              sx={{ '& table': { px: 0 }, '&&& th': { pt: 0 } }}
              data={project.paymentBreakdown}
              columns={[
                { field: 'date', Component: DateCell },
                {
                  field: 'percentage',
                  Component: ({ children }) => (
                    <TableCell>{children}%</TableCell>
                  ),
                },
                'title',
              ]}
            />
          </div>
        </Box>
      )}
    </Card>
  )
}
