import buildSpecQuery from '~/routes/Proposals/FastQuote/Provider/deriveLineItems/helpers/buildSpecQuery'
import { findOptionsMap } from '~/core/sanity/findOptions'
import useWeirdSanityQuery from '~/core/sanity/useWeirdSanityQuery'

const useFilteredSanityOptions = ({ name, category, fields, options }) => {
  const spec = buildSpecQuery(fields)

  const [allowed] =
    useWeirdSanityQuery(`*[_type == 'products' && spec.category == '${category}' 
  ${spec}
 ]{ "${name}": spec.${name} }.${name}[]`)

  const filtered = findOptionsMap(['products', 'spec', name]).map(option => ({
    ...option,
    disabled: !allowed.includes(option.id),
  }))

  return options?.removeDisabled
    ? filtered.filter(({ disabled }) => !disabled)
    : filtered
}

export default useFilteredSanityOptions
