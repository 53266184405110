const booleanToggleButtonGroupProps = {
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  rules: {
    validate: value =>
      typeof value !== 'boolean' ? 'This field is required' : true,
  },
  enforceAtLeastOneSelected: true,
  exclusive: true,
}

export default booleanToggleButtonGroupProps
