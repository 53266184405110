import { Button, Card, Stack } from '@mui/material'
// import Grid from '@mui/material/Unstable_Grid2/Grid2'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Outlet } from 'react-router'
import client from '~/client'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import sharedProps from '~/components/sharedProps'
import Link from '~/config/Link'
import { useParam } from '~/core/router/useParams'
import AddNewUser from '~/components/Users/AddNewUser'
import EditUser from '~/components/Users/EditUser'
import UsersTable from '~/components/Users/UsersTable'
import DeleteUser from '~/components/Users/DeleteUser'
import { sxAppStylesFlex } from '~/components/Layouts/AppStyles'
import ActionButtons from '~/components/Layouts/SalesApp/ActionButtons'

const findAttr = (item, key) =>
  item.Attributes.find(attr => attr.Name === key)?.Value || ''

export default function CompanyUsers() {
  const userInfo = client.getUserInfo()
  console.log('userInfo', userInfo)
  return (
    <>
      <ActionButtons>
        <Link to="?add-new-user">
          <Button variant="contained" endIcon={<PersonAddIcon />}>
            Add new user
          </Button>
        </Link>
      </ActionButtons>

      <Card sx={sxAppStylesFlex}>
        <UsersTable />
      </Card>

      <Drawer open={useParam('add-new-user')}>
        <AddNewUser
          disableCompany
          restrictToOwnCompany
          values={{ companyId: userInfo.companyId }}
        />
      </Drawer>

      <Drawer open={useParam('edit')}>
        <EditUser />
      </Drawer>

      <Drawer open={useParam('delete')}>
        <DeleteUser />
      </Drawer>
      {/* <Drawer
        open={useParam('edit')}
        nothing={console.log(useParam('edit'))}
      >
        EDIT SUBPATCH
      </Drawer> */}
      <Outlet />
    </>
  )
}
