import product from './product'
// import productList from './productList'
// import template from './template'

// import node from './reactflow/node'
// import edge from './reactflow/edge'
// import flow from './reactflow/flow'
import video from './video'
import {
  boardCollection,
  board,
  column,
  card,
  subcard,
  message,
} from './salesResources/board'

import portableText from './portableText'

import banner from './banner'

export const schemaTypes = [
  // main shit yo
  product,
  // productList,
  // node, edge, flow
  boardCollection,
  board,
  column,
  card,
  subcard,
  message,
  portableText,
  video,
  banner,
]
