import sanityClient from '~/client/sanity'
import buildSpecQuery from '../../FastQuote/Provider/deriveLineItems/helpers/buildSpecQuery'

const buildQuery = fields => {
  const { tonnage, direction } = fields.existing
  const { outletAdapter, orientation } = fields.replacement

  const heatingCoil =
    fields.existing.heatingCoil || fields.replacement.heatingCoil
  const coolingCoil = true // fields.existing.coolingCoil || fields.replacement.coolingCoil

  const hvacType =
    heatingCoil && coolingCoil
      ? 'heatingCooling'
      : coolingCoil && !heatingCoil
      ? 'cooling'
      : !coolingCoil && heatingCoil
      ? 'heating'
      : undefined

  const params = [
    {
      key: 'blower',
      category: 'airHandler',
      spec: {
        tonnage,
        hvacType,
        orientation,
        direction,
        motorType: ['variable'],
      },
    },
    {
      key: 'coolingCoil',
      category: 'coil',
      spec: {
        tonnage,
        orientation,
        direction,
        coolingType: ['refrigerant'],
        hvacType,
        // hvacType: ['cooling'],
      },
    },
    heatingCoil && {
      key: 'heatingCoil',
      // category: orientation?.includes('horizontal') ? 'coil' : 'coilAccessory',
      category: orientation === 'horizontal' ? 'coil' : 'coilAccessory',
      spec: {
        tonnage,
        // hvacType,
        orientation,
        heatingType: ['hydro'],
        coilType: ['hotWater'],
        // hvacType: ['heating'],
        // heatingType: ['heatPump', 'electric', 'hydro'],
      },
    },
    {
      key: 'supplyAdapter',
      category: 'outlet',
      spec: {
        tonnage,
        outletCategory: ['supplyPlenumAccessory'],
        outletAdapter,
      },
    },
    fields.existing?.replaceReturnBox && {
      key: 'returnAdapter',
      category: 'returnAirPart',
      spec: {
        tonnage,
        returnAirPartType: ['adaptor'],
        hvacType,
        // coolingType: ['chilledWater'],
        // heatingType,
      },
    },
  ].filter(Boolean)

  const dodgyVerticalPlenumModuleQueryPleaseRefactor = [
    'verticalUpflowModular',
    'verticalDownflow',
  ].includes(orientation)
    ? `,
    "verticalPlenumModule": 
      *[_type == 'products' && 
      spec.category == 'airHandler' &&
      modelNumber in ['M4860V2','M2430V2','M3642V2','M3036V2'] &&
      (!defined(generation) || generation == 4) 
      ${buildSpecQuery({ tonnage })}
      ]
      [0...10] { 
        modelNumber, 
        title,
        // description,
        "category": spec.category, 
        "price": price.global.USD }`
    : ''

  const query =
    params
      .map(
        ({ category, spec, key = category }) =>
          `"${key}": *[_type == 'products' && spec.category == '${category}'
          && (!defined(generation) || generation == 4)
          ${buildSpecQuery(spec)}]
          [0...10] { 
            modelNumber, 
            title,
            "dimensions": spec.dimensions,
            // description,
            "category": spec.category, 
            "price": price.global.USD }`
      )
      .join(',') + dodgyVerticalPlenumModuleQueryPleaseRefactor

  return '{' + query + '}'
}

const getSanityProducts = async state => {
  if (!state.fields.existing || !state.fields.replacement) return

  const products = await sanityClient.fetch(buildQuery(state.fields))

  return products
}

export default getSanityProducts
