import { SearchOff } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useFormContext } from 'react-hook-form-mui'
import formatSearchObject from './formatSearchObject'

export default function AutoSearchResetButton({ sx, ...rest }) {
  const { reset, watch } = useFormContext()

  const values = watch()

  const searchActive = Boolean(formatSearchObject(values))

  return (
    <Button
      variant="contained"
      size="small"
      color="warning"
      endIcon={<SearchOff />}
      onClick={() => reset()}
      sx={{
        ...sx,
        py: 0.5,
        px: 1.5,
        '&.Mui-disabled': { bgcolor: 'background.paper' },
      }}
      disabled={!searchActive}
      {...rest}
    >
      Clear filters
    </Button>
  )
}
