import { Box, Card, Stepper } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import { AnimatePresence } from 'framer-motion'

import isDev from '~/config/isDev'
// i think the * is rendered always so picks up the other form bits?

import { motionStepProps } from '~/core/stepper/motionStepProps'
import useParamsStepRouter from '~/core/stepper/useParamsStepRouter'

import steps from './Steps/steps'
import {
  useReplacementGuideActions,
  useReplacementGuideState,
} from './Provider/ReplacementGuideProvider'
import React from 'react'
import StyledStepper from '../FastQuote/StyledStepper'

export default function ReplacementGuide({ subroutes, ...rest }) {
  const {
    Component,
    name,
    label,
    index,
    stepTo,
    direction,
    onNextClick,
    onPrevClick,
    isNextStep,
    isPrevStep,
  } = useParamsStepRouter({ steps })

  const dispatch = useReplacementGuideActions()
  const { fields, products } = useReplacementGuideState()

  const canStepTo = i => {
    if (isDev()) return true

    if (
      [
        i < 0 || i >= steps.length,
        !fields.existing,
        i > 1 && !fields.replacement,
        i > 3 && !products?.length,
      ].some(Boolean)
    )
      return false

    return true
  }

  const onSuccess = data => {
    dispatch('setFields', data)
    onNextClick()
  }

  React.useEffect(() => {
    if (index > 0 && !fields.existing) stepTo(0)
    else if (index > 1 && !fields.replacement) stepTo(1)
  }, [fields, name])

  return (
    <>
      {/* <Box sx={{ p: 2, py: 4, mb: 3 }}> */}
      <StyledStepper activeStep={index} alternativeLabel sx={{ maxWidth: 860 }}>
        {steps.map(({ name, label }, i) => (
          <Step key={name} onClick={() => canStepTo(i) && stepTo(i)}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
      {/* </Box> */}
      <Box sx={{ overflow: 'hidden', position: 'relative' }}>
        <AnimatePresence initial={false} custom={direction}>
          <Box key={name} {...motionStepProps} custom={direction}>
            <Component
              onSuccess={onSuccess}
              shouldUnregister={false}
              defaultValues={fields}
              onPrevClick={index > 0 && onPrevClick}
              onNextClick={isNextStep && onNextClick}
            />
          </Box>
        </AnimatePresence>
      </Box>
    </>
  )
}
