import React from 'react'

import { enqueueSnackbar } from 'notistack'
import useSWR from 'swr'
import client from '~/client'

const useProposalEstimates = ({ proposalId }) => {
  const { data, mutate, ...rest } = useSWR(
    `distributorEstimates?proposalId=${proposalId}&search=${encodeURIComponent(
      '{"status":"completed,requested"}'
    )}`,
    client.list,
    {
      revalidateOnFocus: false,
    }
  )

  const [isCancelling, setIsCancelling] = React.useState(false)
  const cancelEstimate = async estimate => {
    const { distributorEstimateId } = estimate
    const userInfo = client.getUserInfo()

    const status = userInfo.role.startsWith('contract')
      ? 'cancelledByContractor'
      : 'cancelledByDistributor'
    try {
      setIsCancelling(distributorEstimateId)
      await client.put(`distributorEstimates/${distributorEstimateId}`, {
        status,
      })
      mutate()
      enqueueSnackbar('Estimate cancelled', { variant: 'success' })
    } catch (e) {
      console.error('Error cancelling estimate', e)
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsCancelling(false)
    }
  }

  const [isRequesting, setIsRequesting] = React.useState(false)
  const requestEstimate = async estimate => {
    setIsRequesting(estimate.distributorId)
    try {
      await client.post('distributorEstimates', estimate)
      mutate()
      enqueueSnackbar('Request for estimate submitted', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setIsRequesting(false)
    }
  }

  return {
    data,
    mutate,
    requestEstimate,
    isRequesting,
    cancelEstimate,
    isCancelling,
    ...rest,
  }
}

export default useProposalEstimates
