import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Box, Button, Divider } from '@mui/material'
import { useFormContext } from 'react-hook-form-mui'
import flexSplitSx from '~/core/sx/flexSplitSx'

export default function StepButtons({ onNextClick, onPrevClick }) {
  const formContext = useFormContext()
  return (
    <>
      <Divider flexItem sx={{ mt: 2 }} />
      <Box sx={{ ...flexSplitSx, py: 1 }}>
        {onPrevClick && (
          <Button
            variant="contained"
            onClick={onPrevClick}
            startIcon={<ChevronLeft />}
            sx={{ ml: 0, mr: 'auto' }}
          >
            Back
          </Button>
        )}
        {(onNextClick || formContext) && (
          <Button
            variant="contained"
            onClick={formContext === null ? onNextClick : undefined}
            endIcon={<ChevronRight />}
            sx={{ mr: 0, ml: 'auto' }}
            type={typeof onNextClick === 'function' ? 'button' : 'submit'}
          >
            Continue
          </Button>
        )}
      </Box>
    </>
  )
}
