import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import {
  CheckboxButtonGroup,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import sharedProps from '~/components/sharedProps'
import sxFlexStack from '~/core/sx/sxFlexStack'
import useUserProfile from '~/routes/Profile/useUserProfile'
import { UpdatePasswordFields } from '~/routes/Auth/UpdatePassword'
import StepButtons from '~/routes/Onboarding/StepButtons'

export default function OnboardingUserDetails({
  fieldProps,
  children,
  ...rest
}) {
  const { data, error, isLoading, updateUserProfile } = useUserProfile()

  const formContext = useForm({
    defaultValues: data,
    shouldUnregister: true,
  })

  React.useEffect(() => void formContext.reset(data), [data])

  return (
    <>
      <FormContainer {...rest} formContext={formContext}>
        <Card {...sharedProps.card}>
          <Box sx={sxFlexStack}>
            <Typography variant="h4">Please confirm your details</Typography>

            <TextFieldElement
              required
              fullWidth
              name="firstName"
              label="First name"
              {...fieldProps}
            />

            <TextFieldElement
              required
              fullWidth
              name="lastName"
              label="Last name"
              {...fieldProps}
            />

            <TextFieldElement
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              disabled
              helperText="You can't update your email address here"
            />

            <TextFieldElement
              required
              fullWidth
              name="phoneNumber"
              label="Phone number"
              type="tel"
              {...fieldProps}
            />

            <TextFieldElement
              required
              fullWidth
              name="jobTitle"
              label="Job title"
              {...fieldProps}
            />
          </Box>
        </Card>

        <Card sx={{ ...sharedProps.card.sx, ...sxFlexStack }}>
          <Typography variant="h4">
            How would you prefer Unico support to contact you?
          </Typography>
          <CheckboxButtonGroup
            required
            name="preferredSupportChannels"
            options={[
              {
                id: 'email',
                label: 'Email',
              },
              {
                id: 'sms',
                label: 'Phone',
              },
            ]}
          />

          <Typography>You can select one or both options.</Typography>
        </Card>

        {/* <StepButtons /> */}
        {children}
      </FormContainer>
      <LoadingIndicators isLoading={isLoading} />
    </>
  )
}
