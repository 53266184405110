// probsbly make a common component

import usePageQuery from '~/client/usePageQuery'
import AutoTable, {
  ChipCell,
  DateTimeCell,
  FullNameCell,
} from '~/components/Data/AutoTable/AutoTable'

const columns = [
  { field: 'timestamp', headerName: 'Date', component: DateTimeCell },
  { field: 'action', headerName: 'Action', component: ChipCell },
  { field: 'name', component: FullNameCell, sortable: false },
  { field: 'email', headerName: 'Email', sortable: false },
  { field: 'role', component: ChipCell, sortable: false },
]
export default function AuditLogTable({
  query = 'auditLog',
  entityType,
  entityId,
}) {
  const response = usePageQuery([query, entityType, entityId].join('/'))
  return <AutoTable {...response} columns={columns} />
}
