import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import useSWR from 'swr'

const fetcher = (...args) =>
  fetch(...args)
    .then(res => res.json())
    .catch(e => {})

const useMaintenance = ({
  path = '/maintenance',
  url = '/maintenance.json',
} = {}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  React.useEffect(() => {
    if (data && !error && location.pathname !== path)
      navigate(path, { state: data })
    else if (location.pathname === path) navigate('/')
  }, [data, error])
}
export default useMaintenance
