import { useLocation } from 'react-router'
import routes from '~/routes'

function findChildrenAtDepth(route, segments) {
  let index = 0

  let currentRoute = route
  while (index < segments.length) {
    const potentialRoute = currentRoute.children.find(
      child => child?.path === segments[index]
    )
    if (index === segments.length - 1) {
      if (potentialRoute?.children?.length) return potentialRoute.children
      else return currentRoute?.children
    }
    currentRoute = potentialRoute
    index++
  }
}

const useSiblings = ({ maxDepth, nav: propsNav }) => {
  const { pathname } = useLocation()
  let segments = pathname.split('/').filter(Boolean).slice(0, maxDepth)
  const siblings = findChildrenAtDepth(routes[0], segments)?.filter(
    route => !route.index
  )

  if (propsNav)
    return siblings?.filter(route =>
      typeof route.handle?.nav === 'function'
        ? route.handle.nav()
        : route.handle.nav === true
    )

  return siblings
}

export default useSiblings
