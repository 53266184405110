import { Box } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import FanIcon from './FanIcon'

const props = {
  component: motion.svg,
  variants: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  },
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
}
export default function FanLoader({ isLoading }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme => theme.palette.primary.main,
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      <AnimatePresence>
        {isLoading && <FanIcon sx={{ fontSize: 56 }} {...props} />}
      </AnimatePresence>
    </Box>
  )
}
