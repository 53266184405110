import React, { createContext, useContext, useReducer } from 'react'
import { useNavigate, useParams } from 'react-router'
import getBoundingClientRect from '~/routes/SalesResources/Provider/getBoundingClientRect'

const BoardStateContext = createContext()
const BoardActionsContext = createContext()

const reducer = (state, action) => state

const initialBounds = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
}

const dispatch = (type, payload) =>
  typeof type === 'string' ? _dispatch({ type, payload }) : _dispatch(type)

const BoardProvider = ({ children }) => {
  // const [state, _dispatch] = useReducer(reducer, initialState())

  const navigate = useNavigate()

  const boardRef = React.useRef(null)
  const { boardId, cardId, subcardId } = useParams()
  const [boardBounds, setBoardBounds] = React.useState(initialBounds)

  const updateBoardBounds = () => {
    if (!boardRef?.current) return
    const boardBounds = getBoundingClientRect(boardRef.current)
    setBoardBounds(boardBounds)
    console.log('set board bounds', boardBounds)
  }

  React.useEffect(updateBoardBounds, [cardId, boardRef?.current])

  // doesn't trigger update on child yet
  React.useEffect(() => {
    window.addEventListener('resize', updateBoardBounds)
    return () => window.removeEventListener('resize', updateBoardBounds)
  }, [boardRef?.current])

  console.log('BOARD ID', boardId)

  const toSubcard = subcardId => navigate(`${boardId}/${cardId}/${subcardId}`)
  const toCard = cardId => navigate(`${boardId}/${cardId}`)
  const toBoard = e => navigate(`${typeof e === 'string' ? e : boardId}`)
  const toRoot = e => navigate('')

  return (
    <BoardStateContext.Provider
      value={{
        boardRef,
        boardId,
        cardId,
        boardBounds,
        toSubcard,
        toCard,
        toBoard,
        toRoot,
        initialBounds,
      }}
    >
      <BoardActionsContext.Provider value={{}}>
        {children}
      </BoardActionsContext.Provider>
    </BoardStateContext.Provider>
  )
}

const useBoardState = () => useContext(BoardStateContext)
const useBoardActions = () => useContext(BoardActionsContext)

export { BoardProvider, useBoardActions, useBoardState }
