import { Typography } from '@mui/material'
import AutoTable from '~/components/Data/AutoTable/AutoTable'
import { useReplacementGuideState } from '../Provider/ReplacementGuideProvider'

export default function SummaryProductsTable({
  onNextClick,
  onPrevClick,
  ...rest
}) {
  const { lineItems, products } = useReplacementGuideState()

  return (
    <>
      {(products?.coolingCoil?.length || products?.heatingCoil?.length) && (
        <Typography>
          The correct coil to use depends on the specific outdoor model. The
          listed coil is provided as a guide. Call Unico Customer Support for
          further assistance <a href="tel:(800) 527-0896">(800) 527-0896</a>.
        </Typography>
      )}

      <AutoTable
        appStyles
        {...rest}
        sx={{
          '& td:nth-child(1)': { width: '150px' },
        }}
        columns={['category', 'modelNumber', 'title']}
        data={lineItems}
      />
    </>
  )
}
