import { Box, Card, Divider, Typography } from '@mui/material'
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { findOptionsMap } from '~/core/sanity/findOptions'
import cardSx from '~/core/sx/cardSx'
import sxFlexStack from '~/core/sx/sxFlexStack'
import { useReplacementGuideState } from '../Provider/ReplacementGuideProvider'
import StepButtons from './StepButtons'

const DesignCheckRow = ({ children, title, description, valid, ...rest }) => (
  <>
    <Divider />
    <Box
      {...rest}
      sx={{
        borderLeft: 8,
        p: 2,
        pl: 3,
        borderColor: valid ? 'success.main' : 'warning.main',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        '& > :nth-of-type(1)': { width: '60%', flexShrink: 0 },
      }}
    >
      <div>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </div>
      <div>{children}</div>
    </Box>
  </>
)

export default function DesignCheck({
  onNextClick,
  onPrevClick,
  onSuccess,
  ...rest
}) {
  const state = useReplacementGuideState()
  const formContext = useForm(rest)
  const { watch } = formContext

  const blowerValid = watch('designCheck.blowerWillFit')

  const tonnage = state.fields.existing?.tonnage
  const options = findOptionsMap(['products', 'spec', 'tonnage'])
  const number = options.find(({ value }) => value === tonnage)?.number
  const minimumOutlets = Math.ceil(number * 7)
  const outletsValid = watch('designCheck.outletQuantity') >= minimumOutlets

  const ductSizeValid = watch('designCheck.ductSize')

  // const dimensions = state?.products?.airHandler?.dimensions
  const dimensions = Object.values(state?.products)
    .flat()
    .find(p => p.category === 'airHandler')?.dimensions

  const dimensionsCopy = `${dimensions?.width}" width x ${dimensions?.height}" height x ${dimensions?.depth}" depth`

  return (
    <FormContainer formContext={formContext} onSuccess={onSuccess}>
      <Card sx={{ ...cardSx, ...sxFlexStack, gap: 2 }}>
        <Typography variant="h4">Design check</Typography>
        <Typography>
          Check other aspects of the existing system to ensure the best
          compatibility.
        </Typography>

        <div>
          <DesignCheckRow
            valid={outletsValid}
            title="Outlets"
            description={`Based on the replacement blower, the system will need ~7 outlets per tonne. This system needs ${minimumOutlets} outlets.`}
          >
            <TextFieldElement
              name="designCheck.outletQuantity"
              label="Existing number of outlets"
              type="number"
            />
          </DesignCheckRow>

          <DesignCheckRow
            valid={ductSizeValid}
            title="Duct size"
            description="Based on the replacement blower, the system will need a 7” round, or 6.5” square size duct."
          >
            <CheckboxElement
              name="designCheck.ductSize"
              label="Existing size matches new requirement"
            />
          </DesignCheckRow>

          <DesignCheckRow
            valid={blowerValid}
            title="Blower dimensions"
            description={`Please check the existing installation area to ensure the new blower will fit. The new blower requires at least ${dimensionsCopy}.`}
          >
            <CheckboxElement
              name="designCheck.blowerWillFit"
              label="The new blower will fit"
            />
          </DesignCheckRow>
        </div>

        <Divider />
        <StepButtons {...{ onPrevClick }} />
      </Card>
    </FormContainer>
  )
}
