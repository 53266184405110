import { alpha, styled } from '@mui/material'
import { Outlet } from 'react-router'
import PageHeader from '~/components/Layouts/SalesApp/PageHeader'
import ReplacementGuide from './ReplacementGuide'
import { ReplacementGuideProvider } from './Provider/ReplacementGuideProvider'

const StyledRoot = styled('div')(({ theme }) => ({
  '& .MuiToggleButtonGroup-root:has(.MuiToggleButton-sizeMedium)': {
    flexWrap: 'wrap',
    '& .MuiToggleButton-sizeMedium': {
      '&:not(:last-of-type)': {
        marginRight: 8,
      },
      marginBottom: 8,
      border: '2px solid rgba(0, 0, 0, 0.12) !important',
      borderWidth: '2px !important',
      marginRight: -1,
      // border: '2px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px !important',
      '&.Mui-selected': {
        border: `2px solid ${alpha(
          theme.palette?.secondary?.main || '#000',
          0.7
        )} !important`,
      },
      '.Mui-error + &': {
        border: `2px solid ${alpha(theme.palette.error.dark, 0.6)} !important`,
      },
    },
  },
}))

export default function ReplacementGuidePage() {
  return (
    <StyledRoot>
      <ReplacementGuideProvider>
        <ReplacementGuide />
      </ReplacementGuideProvider>
      <Outlet />
    </StyledRoot>
  )
}
