import { createContext, useContext, useEffect, useReducer } from 'react'
import getSanityProducts from './getSanityProducts'
import { camelToSentence } from '~/core/utils/formatString'

const ReplacementGuideStateContext = createContext()
const ReplacementGuideActionsContext = createContext()

const initialState = () => ({ fields: {}, checklist: {} })

const reducer = (state, action) => {
  console.log(action.type, action.payload)

  if (action.type === 'setFields')
    return { ...state, fields: { ...state.fields, ...action.payload } }

  if (action.type === 'productsFetched') {
    return {
      ...state,
      products: action.payload,
      lineItems: Object.entries(action.payload || {})
        .flatMap(([key, products]) =>
          products.map(product => ({
            ...product,
            category: camelToSentence(key || category),
          }))
        )
        .sort((a, b) => a.category.localeCompare(b.category)),
      // tubingProducts,
      // shouldSave: true,
    }
  }

  if (action.type === 'reset') return initialState()

  return state
}

const ReplacementGuideProvider = ({ children, actions }) => {
  const [state, _dispatch] = useReducer(reducer, initialState())
  console.log('state', state)
  const dispatch = (type, payload) =>
    typeof type === 'string' ? _dispatch({ type, payload }) : _dispatch(type)

  useEffect(() => {
    const call = async () => {
      const products = await getSanityProducts(state)
      dispatch({
        type: 'productsFetched',
        payload: products,
      })
    }
    call()
  }, [state.fields])

  return (
    <ReplacementGuideStateContext.Provider value={state}>
      <ReplacementGuideActionsContext.Provider value={dispatch}>
        {children}
      </ReplacementGuideActionsContext.Provider>
    </ReplacementGuideStateContext.Provider>
  )
}

const useReplacementGuideState = () => useContext(ReplacementGuideStateContext)
const useReplacementGuideActions = () =>
  useContext(ReplacementGuideActionsContext)

export {
  ReplacementGuideProvider,
  useReplacementGuideActions,
  useReplacementGuideState,
}
