import { Card } from '@mui/material'
import React from 'react'
import AssetList from '~/routes/Company/Assets/AssetList'

export default function Assets({ data }) {
  const assets = data?.project?.companyAssets

  if (!assets) return null

  return (
    <Card sx={{ p: 2 }}>
      <AssetList
        assets={assets}
        sx={{
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr) !important',
            sm: 'repeat(3, 1fr) !important',
            md: 'repeat(4, 1fr) !important',
            lg: 'repeat(6, 1fr) !important',
          },
        }}
      />
    </Card>
  )
}
