import { alpha, Stepper, styled } from '@mui/material'

const StyledStepper = styled(Stepper)(({ theme }) =>
  theme.unstable_sx({
    py: { xs: 1, sm: 2, md: 4 },
    maxWidth: 800,
    mx: 'auto',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-end',
      position: 'sticky',
      top: 56,
      height: 'auto',
      // width: '70%',
      minWidth: 100,
      background: alpha(theme.palette.background.default, 0.6),
      backdropFilter: 'blur(10px)',
      mb: 1,
      zIndex: theme.zIndex.appBar - 1,
      '& .MuiStepConnector-line': {
        display: 'none',
      },
      '& .MuiStep-root': {
        px: 0.5,
      },
      // '& .MuiStepButton-root': { py: 0 },
      '& .MuiStepLabel-labelContainer': { display: 'none' },
      '& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed': {
        // fill: 'rgba(255,255,255,0.2)',
        '& .MuiStepIcon-text': {
          // fill: theme.palette.primary.main,
          // fill: theme.palette.primary.contrastText,
        },
      },
    },
  })
)

export default StyledStepper
