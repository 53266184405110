import { ToggleButtonGroupElement } from 'react-hook-form-mui'
import useAutoDeselect from './useAutoDeselect'

const DeselectingToggleButtonGroupElement = props => {
  const { name, options } = props
  useAutoDeselect({ name, options })

  return <ToggleButtonGroupElement {...props} />
}

export default DeselectingToggleButtonGroupElement
