import { Outlet } from 'react-router'
import useScript from '~/core/hooks/useScript'
import useMaintenance from '~/routes/Maintenance/useMaintenance'
import useResumeOnboarding from '~/routes/Onboarding/useResumeOnboarding'
import AppStyles from './AppStyles'
import { MultiPortalProvider } from './MultiPortal'

export default function LayoutRoot() {
  useMaintenance()

  useScript({
    src: 'https://static.zdassets.com/ekr/snippet.js?key=ba8e5993-37bb-45de-b4de-13e49a2f036e',
    // enabled: isDev(),
  })

  useResumeOnboarding()

  return (
    <MultiPortalProvider>
      <Outlet />
    </MultiPortalProvider>
  )
}
