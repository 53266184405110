import { Box, GlobalStyles, Typography } from '@mui/material'
import React from 'react'
import { useMatches } from 'react-router'

import Breadcrumbs from './Breadcrumbs'

function reverseTraverseAndGetTitles(matches) {
  for (let i = matches.length - 1; i >= 0; i--)
    if (matches[i].handle?.title) return matches[i].handle.title
}

export default function PageHeader({ title: propsTitle, children, app }) {
  const matches = useMatches()
  const title = propsTitle || reverseTraverseAndGetTitles(matches) || ' '

  // if (!title && !children) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        justifyContent: 'flex-end',
        // width: '100%',
        pt: { xs: 1, md: 4, lg: 8 },
        // pb: { xs: 1, md: 2, lg: 3 },
        mb: { xs: 1, md: 2, lg: 3 },
        // borderBottom: { md: '1px solid #E0E0E0' },
        order: -10,
        width: '100%',
        display: { xs: 'none', md: 'block' },
      }}
    >
      {/* <GlobalStyles
        styles={{
          'body #root main': {
            justifyContent: 'space-between',
            alignItems: 'start',
            flexDirection: 'row',
            flexWrap: 'wrap',
            // maxWidth: '100%',
          },
        }}
      /> */}
      <Box
        sx={{
          ml: 0,
          mr: 'auto',
          // position: 'fixed',
        }}
      >
        <Typography variant="h1">{title}</Typography>
        {/* <Breadcrumbs /> */}
      </Box>
      {children}
    </Box>
  )
}
