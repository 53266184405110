import useSWR from 'swr'
import client from '~/client'
import { camelToSentence } from '~/core/utils/formatString'

export default function useCompanyOptions({ companyType } = {}) {
  const q = companyType
    ? encodeURIComponent(`{"companyType":"${companyType}"}`)
    : ''

  const { data } = useSWR(`company-options/?search=${q}`, client.fetcher)
  console.log('useCompanyOptions', companyType, data)

  return (
    data?.map(({ companyId, companyName, companyType }) => ({
      id: companyId,
      label: companyName + ` (${camelToSentence(companyType)})`,
      companyType,
    })) || []
  )
}
