import { Send } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  CheckboxElement,
  FormContainer,
  MultiSelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import client from '~/client'
import SubmitButton from '~/components/Data/SubmitButton'
import useDistributors from '~/routes/Proposals/FastQuote/useDistributors'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import ConsistentDialog from '~/components/Modals/ConsistentDialog'

const makeDefaultValues = userInfo => ({
  subject: 'Materials list for proposal',
  body: `Hello,
Please find attached a material list for a Unico job.

Can you please fill out the appropriate columns and send it back to me so I can coordinate timings with the client.

Please contact me any time if you have questions.
${userInfo.email}
${userInfo.phoneNumber}

Thank you,

${userInfo.firstName} ${userInfo.lastName}
${userInfo.address?.line1}
${userInfo.address?.line2}
${userInfo.address?.state} ${userInfo.address?.zipCode}
${userInfo.email}`,
})

// THIS KEEPS CLOSING BECAUSE IT GETS NEW DATA WITH NEW KEYS?
export default function SendMaterialsListDialog({
  lineItems,
  open = !!proposal,
  onClose,
  ...rest
}) {
  const userInfo = client.getUserInfo()
  const formContext = useForm({
    defaultValues: makeDefaultValues(userInfo),
  })

  const { watch, getValues } = formContext

  const { distributorsOptions, getDistributor } = useDistributors()

  const distributorIds = watch('distributorIds')
  const canSend = distributorIds?.length || lineItems?.length

  const [isSending, setIsSending] = React.useState(false)

  const handleSendClick = async () => {
    setIsSending(true)
    try {
      const data = getValues()
      const { distributorIds, include, subject, body, cc } = data

      const res = await client.post('distributor/send-materials-list/', {
        distributorIds,
        lineItems,
        subject,
        body,
        sendToMyself: cc,
      })

      enqueueSnackbar(
        `Materials list has been emailed to ${
          distributorIds.length
        } distributor${distributorIds.length > 1 ? 's' : ''}`,
        { variant: 'success' }
      )
      onClose()
    } catch (e) {
      console.error(e)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <ConsistentDialog
      {...{ open, onClose }}
      maxWidth="sm"
      fullWidth
      title="Send materials list"
    >
      <DialogContent>
        <LoadingIndicators isSubmitting={isSending} />
        <FormContainer formContext={formContext}>
          <Stack
            direction="column"
            spacing={2}
            sx={{ alignItems: 'flex-start' }}
          >
            <Divider flexItem />
            {/* TODO: extratc this where it's used multiple */}
            <MultiSelectElement
              xs={12}
              md={6}
              name="distributorIds"
              fullWidth
              label="Choose distributors"
              multiple
              options={distributorsOptions}
              showCheckbox
              renderValue={selected =>
                selected
                  .map(
                    id =>
                      distributorsOptions.find(option => option.id === id).label
                  )
                  .join(', ')
              }
            />
            <TextFieldElement
              fullWidth
              required
              type="text"
              label="Subject"
              name="subject"
              // placeholder=""
            />
            <TextFieldElement
              fullWidth
              multiline
              minRows={3}
              // rows={3}
              required
              type="text"
              label="Message body"
              name="body"
              placeholder="Write a custom message here"
            />
            <CheckboxElement name="cc" label="Send a copy to myself" />
            <Stack
              direction="row"
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <SubmitButton
                type="button"
                endIcon={<Send />}
                color="success"
                onClick={handleSendClick}
                disabled={!canSend}
                isSubmitting={isSending}
              >
                Send email{distributorIds?.length > 1 && 's'}
              </SubmitButton>
            </Stack>
          </Stack>
        </FormContainer>
      </DialogContent>
    </ConsistentDialog>
  )
}
