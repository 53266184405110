import { Box, Button, Card, Container, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { useNavigate } from 'react-router'
import client from '~/client'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmittingIcon from '~/components/Data/SubmittingIcon'
import sharedProps from '~/components/sharedProps'

const variants = {
  initial: { y: '-100%' },
  animate: { y: 0 },
  exit: { y: '-100%' },
}

const defaultValues = {
  username: '',
  password: '',
}

export default function SignIn() {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = React.useState(false)
  const [success, setSuccess] = React.useState()

  const formContext = useForm({
    defaultValues,
  })

  React.useEffect(() => {
    if (client.isSignedIn()) navigate('/app')
  }, [])

  const handleClick = async data => {
    try {
      setSubmitting(true)
      const res = await client.forgotPassword(data)
      console.log(res)
      if (res.success) {
        setSuccess(true)
        // enqueueSnackbar(
        //   'If your email is registered with us, we have sent you a password reset email. It may take several minutes to show up in your inbox. Please check your spam folder as well.',
        //   { variant: 'success' }
        // )
        setSubmitting(false)
      } else {
        console.error(res)
        throw res.message || 'Error signing in'
      }
    } catch (e) {
      setSubmitting(false)
      enqueueSnackbar(e.toString(), {
        variant: 'error',
      })
      console.error('signup error caught ', e)
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      as={motion.div}
      variants={variants}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
        <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
          Reset Password
        </Typography>
      </Box>
      <Card sx={{ p: 3, py: 4, position: 'relative' }}>
        <FormContainer formContext={formContext} onSuccess={handleClick}>
          <TextFieldElement
            fullWidth
            name="username"
            id="username"
            label="Email"
            required
            sx={{ mb: 3 }}
            autoComplete="username"
            type="email"
          />

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button
              sx={{ flexShrink: 0, alignSelf: 'start' }}
              {...sharedProps.button.save}
              endIcon={<SubmittingIcon active={submitting} />}
            >
              Reset Password
            </Button>
          </Box>
        </FormContainer>
        {success && (
          <Typography>
            If your email is registered with us, we have sent you a password
            reset email. It may take several minutes to show up in your inbox.
            Please check your spam folder as well.
          </Typography>
        )}
        <LoadingIndicators isSubmitting={submitting} />
      </Card>
    </Container>
  )
}
