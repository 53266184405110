import { Box, Card, Grid, Typography } from '@mui/material'
import client from '~/client'
import Chip from '~/components/Data/Chip'
import sharedProps from '~/components/sharedProps'
import { formatDate } from '~/core/utils/formatDate'
import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'
import RelatedRepCompanyInfo from '~/routes/Profile/RelatedRepCompanyInfo'
import useUserProfile from '~/routes/Profile/useUserProfile'
import EditProfile from './EditProfile'

export default function Profile() {
  const userInfo = client.getUserInfo()

  const { data: company } = useCompanyDetails()

  const {
    data: user,
    error,
    isLoading,
    isSubmitting,
    updateUserProfile,
  } = useUserProfile()
  console.log('userInfo', { userInfo, company, user })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {[user?.firstName, user?.lastName].filter(Boolean).join(' ')}
            </Typography>
            <Box {...sharedProps.pageInfo}>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Role
                </Typography>
                <Chip label={userInfo.role} />
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Status
                </Typography>
                <Chip size="small" label="Active" color="success" />
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Joined
                </Typography>
                <Typography>{formatDate(user?.inviteAcceptedAt)}</Typography>
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Company
                </Typography>
                <Typography>{company?.companyName}</Typography>
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Email
                </Typography>
                <Typography>{userInfo?.email}</Typography>
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={4}>
          <EditProfile
            fieldProps={{ disabled: client.hasOnlyRoles(['demo_user']) }}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={3}>
          <RelatedRepCompanyInfo company={company} />
        </Grid>
      </Grid>
    </>
  )
}
