import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'

import { Box, Card, InputAdornment, Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import SubmitButton from '~/components/Data/SubmitButton'
import sharedProps from '~/components/sharedProps'
import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'
import ConsistentPopover from '~/components/Modals/ConsistentPopover'
import { Save, SaveOutlined } from '@mui/icons-material'
// import { alphaChars } from '~/core/utils/regex'

export default function GlobalPercentagePrice({ companyId } = {}) {
  const { data, error, isLoading, mutate, isSubmitting, onSuccess } =
    useCompanyDetails({ companyId })

  const formContext = useForm({
    shouldUnregister: true,
    defaultValues: data,
  })

  React.useEffect(() => {
    if (data) formContext.reset(data)
  }, [data])

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FormContainer formContext={formContext} onSuccess={onSuccess}>
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ gap: 1 }}>
            Contractor multiplier of Unico list price
            <ConsistentPopover
              sx={{ ml: 1 }}
              title="Contractor multiplier"
              description="For any products without a custom price specified below, this is the proportion of the Unico list price you are selling at."
            />
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'stretch',
              alignItems: 'stretch',
            }}
          >
            <TextFieldElement
              required
              // fullWidth
              name="distributorMultiplier"
              // size="small"

              // label="Contractor multiplier of Unico list price"
              type="number"
              sx={{ flexGrow: 1, '& input': { p: 1.5, minWidth: 100 } }}
              // TODO: stop long numbers limit to 2!! it's really a percentage
              // rules={{ pattern: doubleDigits }}
              InputProps={{
                // startAdornment: '.',
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <SubmitButton isSubmitting={isSubmitting} />
                //   </InputAdornment>
                // ),
                inputProps: {
                  max: 1,
                  min: 0,
                },
              }}
              rules={{
                min: { value: 0.0001, message: 'Must be greater than 0' },
                max: { value: 1, message: 'Must be less than 1' },
              }}
              // helperText="For any products without a custom price specified below, this is the proportion of the Unico list price you are selling at."
            />
            <SubmitButton
              isSubmitting={isSubmitting}
              endIcon={<SaveOutlined />}
              // disabled={formContext.formState.dirtyFields}
            />
          </Box>
        </Stack>
        <LoadingIndicators {...{ isSubmitting, isLoading }} />
      </FormContainer>
    </Card>
  )
}
