import { DeleteOutline } from '@mui/icons-material'
import UpdateIcon from '@mui/icons-material/Update'
import React from 'react'
import client from '~/client'
import AutoSearchMultiCheck from '~/components/Data/AutoTable/AutoSearchMultiCheck'
import AutoTable, * as Table from '~/components/Data/AutoTable/AutoTable'
import { userRoleOptions as allUserRoleOptions } from '~/components/Data/AutoTable/statusOptions'
import FanIcon from '~/components/Data/FanIcon'
import useUsers from '~/components/Users/useUsers'

export default function UsersTable({
  query,
  onEditClick,
  disableEdit,
  userRoleOptions = allUserRoleOptions,
  ...rest
}) {
  const [search, setSearch] = React.useState()
  const { handleDelete, isSubmitting, resendInvitation, mutate, ...response } =
    useUsers({ query, search })

  const canEdit =
    !disableEdit &&
    client.hasRoles([
      'distributor_company_user',
      'contract_firm_admin',
      'distributor_admin',
      'distributor_company_admin',
      'unico_admin',
      'rep_admin',
    ])

  const canResend =
    !disableEdit &&
    client.hasRoles([
      'contract_firm_admin',
      'rep_admin',
      'rep_sales_manager',
      'distributor_admin',
      'distributor_company_admin',
      'unico_admin',
      'super_admin',
    ])

  const userInfo = client.getUserInfo()

  const isSingleCompany = !query || query?.startsWith('companyusers')

  return (
    <>
      <AutoTable
        {...rest}
        // appStyles
        setSearch={setSearch}
        {...response}
        titleField="name"
        uniqueField="email"
        columns={[
          'firstName',
          'lastName',
          'email',
          !isSingleCompany && {
            breakpoints: ['sm'],
            field: 'company.companyName',
          },
          {
            field: 'inviteSentAt',
            Component: Table.DateCell,
            headerName: 'Invited',
          },
          {
            field: 'inviteAcceptedAt',
            Component: props =>
              props.data.status === 'Invited' && canResend ? (
                <Table.ButtonCell
                  {...props}
                  onClick={() => resendInvitation(props.data)}
                  startIcon={
                    isSubmitting === props.data.userId ? (
                      <FanIcon />
                    ) : (
                      <UpdateIcon />
                    )
                  }
                  disabled={isSubmitting === props.data.userId}
                  label="Resend"
                  color="warning"
                />
              ) : (
                <Table.DateCell {...props} />
              ),
            headerName: 'Joined',
          },
          ...(canResend
            ? [
                {
                  field: 'lastLoginAt',
                  Component: Table.DateCell,
                  headerName: 'Last login',
                },
              ]
            : []),
          {
            field: 'status',
            Component: Table.ChipCell,
            headerName: 'Status',
          },
          {
            field: 'role',
            Component: Table.ChipCell,
            headerSearch: <AutoSearchMultiCheck options={userRoleOptions} />,
          },
          ...(canEdit
            ? [
                {
                  field: 'edit',
                  Component: Table.EditCell,
                  onClick: onEditClick,
                },
                {
                  field: 'delete',
                  Component: props =>
                    props.data.userId !== userInfo.userId ? (
                      <Table.ButtonCell {...props} />
                    ) : (
                      <Table.TableCell />
                    ),
                  color: 'error',
                  startIcon: <DeleteOutline />,
                  label: 'Delete',
                  to: '?delete',
                },
              ]
            : []),
        ]}
      />
    </>
  )
}
