function pruneObject(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      pruneObject(obj[key]) // Recurse into nested objects
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key] // Remove empty objects
      }
    } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
      delete obj[key] // Remove empty values
    }
  }
  if (Object.keys(obj).length === 0) return null
  return obj
}

const formatSearchObject = input => {
  if ([null, '', undefined, []].some(v => v === input)) return null

  if (Array.isArray(input)) return input.join(',')

  if (typeof input === 'string') return input.trim()

  if (typeof input.toISOString === 'function') {
    try {
      return input.toISOString()
    } catch (e) {
      return null
    }
  }
  if (typeof input !== 'object') return input

  const output = {} // Create a new object to hold the modified keys

  for (const key in input) {
    if (key === 'contents' || key === 'address') {
      // console.log('extract', extractPaths(input))
      const [path, operator] = Object.entries(input[key])[0]
      const prunedOperator = pruneObject(operator)

      if (Object.keys(prunedOperator || {}).length)
        output[key] = {
          path: path.replace(/-/g, '.'),
          ...prunedOperator,
        }
      continue
    }
    const newKey = key.replace(/-/g, '.')
    const newValue = formatSearchObject(input[key])
    if (newValue !== null) output[newKey] = newValue
  }
  console.log({ input, output: pruneObject(output) })
  return pruneObject(output)
}

export default formatSearchObject

// export default formatSearchObject
// function extractPaths(obj, prefix = '') {
//   let result = []

//   for (const [key, value] of Object.entries(obj)) {
//     const path = prefix ? `${prefix}.${key}` : key

//     if (typeof value === 'object' && value !== null) {
//       // Recursively process nested objects
//       result = result.concat(extractPaths(value, path))
//     } else {
//       // Add the key-value pair to the result
//       result.push({
//         path: path.replace(/-/g, '.'),
//         equals: value,
//       })
//     }
//   }

//   return result
// }
