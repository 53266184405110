import {
  Box,
  Button,
  Card,
  Container,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import client from '~/client'
import sharedProps from '~/components/sharedProps'

import { LoadingBackdrop } from '~/components/Data/LoadingIndicators'
import isDev from '~/config/isDev'
import { motionStepProps } from '~/core/stepper/motionStepProps'
import useParamsStepRouter from '~/core/stepper/useParamsStepRouter'
import flexSplitSx from '~/core/sx/flexSplitSx'
import { testPassword } from '~/routes/Auth/PasswordFeedback'
import OnboardingCompanyDetails from '~/routes/Onboarding/CompanyDetails'
import OnboardingCompanyQuestions from '~/routes/Onboarding/CompanyQuestions'
import OnboardingFinish from '~/routes/Onboarding/Finish'
import {
  shouldOnboardCompany,
  shouldOnboardUser,
} from '~/routes/Onboarding/shouldOnboard'
import StepButtons from '~/routes/Onboarding/StepButtons'
import OnboardingUpdatePassword from '~/routes/Onboarding/UpdatePassword'
import OnboardingUserDetails from '~/routes/Onboarding/UserDetails'
import OnboardingUserQuestions from '~/routes/Onboarding/UserQuestions'

const allSteps = [
  {
    name: 'update-password',
    label: 'Password',
    Component: OnboardingUpdatePassword,
  },
  {
    name: 'user-details',
    label: 'Profile',
    Component: OnboardingUserDetails,
  },
  {
    name: 'user-questions',
    label: 'Usage',
    Component: OnboardingUserQuestions,
    allowPrev: true,
  },
  {
    name: 'company-details',
    label: 'Company',
    Component: OnboardingCompanyDetails,
    allowPrev: true,
  },
  {
    name: 'company-questions',
    label: 'Finish',
    Component: OnboardingCompanyQuestions,
    allowPrev: true,
  },
  {
    name: 'finish',
    Component: OnboardingFinish,
    cta: 'Take me to the app',
  },
]

// const globalStylesInstance = <GlobalStyles styles={globalStyles} />

function Onboarding() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const details = {
    companyId: state?.user?.company?.companyId,
    session: state?.session,
    username: state?.username,
    user: state?.user,
    mode: shouldOnboardCompany(state?.user)
      ? 'company'
      : shouldOnboardUser(state?.user)
      ? 'user'
      : null,
  }
  const [{ companyId, session, username, user, mode }] = React.useState(details)

  const [steps] = React.useState(
    allSteps.filter(
      step => mode === 'company' || !step.name.includes('company')
    )
  )
  const {
    Component,
    name,
    label,
    index,
    stepTo,
    direction,
    onNextClick,
    onPrevClick,
    isNextStep,
    isPrevStep,
    ...activeStep
  } = useParamsStepRouter({ steps })

  React.useEffect(() => {
    if (![companyId, username, mode].every(Boolean)) {
      console.log({ companyId, username, mode })
      enqueueSnackbar('Missing onboarding details', { variant: 'error' })
      if (!isDev()) navigate('/')
    }
  }, [companyId, username, mode])

  const [isSubmitting, setSubmitting] = React.useState(false)

  const updatePassword = async data => {
    try {
      if (data.password !== data.confirmPassword) {
        enqueueSnackbar('Passwords must match', { variant: 'error' })
        return
      }

      if (!testPassword(data.password).every(({ valid }) => valid)) {
        enqueueSnackbar('Password does not meet requirements', {
          variant: 'error',
        })
        return
      }

      setSubmitting(true)
      const res = await client.changePassword({
        session,
        username,
        password: data.password,
      })

      if (client.isSignedIn()) {
        setTimeout(onNextClick)
      } else if (res.message?.name === 'NotAuthorizedException') {
        enqueueSnackbar('Your session has timed out, please try again', {
          variant: 'error',
        })
        navigate(-1)
      } else if (res.message?.name === 'InvalidPasswordException') {
        enqueueSnackbar('Password does not meet requirements', {
          variant: 'error',
        })
      } else
        throw (
          res.message?.message || 'UpdatePassword.jsx: error updating password'
        )
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const updateUserProfile = async data => {
    try {
      setSubmitting(true)
      await client.patch('protected', { ...data, onboarded: true })
      enqueueSnackbar('Your details have been updated')
      onNextClick()
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const updateCompanyDetails = async data => {
    try {
      setSubmitting(true)
      await client.put('companyDetails', {
        ...data,
        companyId,
        onboarded: true,
      })
      onNextClick()
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  const [savedQuestions, setSavedQuestions] = React.useState({})

  const submitQuestions = async data => {
    setSavedQuestions(data)
    try {
      await client.post('hubspot/export', data)
    } catch (e) {
      console.error(e)
    }
  }

  const onSuccess = data => {
    if (name === 'update-password') updatePassword(data)
    else if (name === 'user-details') updateUserProfile(data)
    else if (name === 'user-questions') {
      if (mode === 'user') submitQuestions(data)
      else setSavedQuestions(data)
      onNextClick()
    } else if (name === 'company-details') updateCompanyDetails(data)
    else if (name === 'company-questions') {
      submitQuestions({ ...savedQuestions, ...data })
      onNextClick()
    } else if (name === 'finish') {
      navigate('/app')
    }
  }
  return (
    <>
      {/* <AppStyles /> */}
      <Box
        sx={{
          textAlign: 'center',
          mt: { xs: 0, md: 4 },
          mb: 4,
        }}
      >
        <img src="/logo-unico-dark.svg" alt="The Unico System" />
      </Box>
      <Container maxWidth="sm">
        <Stepper
          activeStep={index}
          alternativeLabel
          sx={theme => ({
            mb: 4,
            [theme.breakpoints.only('xs')]: {
              // '& .MuiStepConnector-line': { display: 'none' },
              '.MuiStepLabel-labelContainer': { display: 'none' },
              '& .MuiStep-root': { px: 0.5 },
            },
          })}
        >
          {steps
            .filter(step => step.label)
            .map(({ name, label }, i) => (
              <Step key={name} onClick={() => isDev() && stepTo(i)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
        </Stepper>
      </Container>

      <Box sx={{ overflow: 'hidden', pb: 8 }}>
        <AnimatePresence initial={false} custom={direction}>
          <Box key={name} {...motionStepProps} custom={direction}>
            <Container maxWidth="sm">
              <Box sx={{ position: 'relative' }}>
                <Component onSuccess={onSuccess} defaultValues={savedQuestions}>
                  {name === 'finish' ? (
                    <Card
                      sx={{
                        ...flexSplitSx,
                        ...sharedProps.card.sx,
                        justifyContent: 'center',
                      }}
                    >
                      <Button variant="contained" onClick={onSuccess}>
                        Take me to the app!
                      </Button>
                    </Card>
                  ) : (
                    <StepButtons
                      isSubmitting={isSubmitting}
                      onPrevClick={activeStep.allowPrev && onPrevClick}
                      cta={activeStep?.cta}
                    />
                  )}
                </Component>
              </Box>
            </Container>
          </Box>
        </AnimatePresence>
        <LoadingBackdrop active={isSubmitting} />
      </Box>
    </>
  )
}
export default Onboarding
