import { useSearchParams } from 'react-router'

const useParams = () => {
  const [params, _setSearchParams] = useSearchParams()

  const setSearchParams = (fn, options) => {
    typeof fn === 'function'
      ? _setSearchParams(fn(Object.fromEntries(params)), options)
      : _setSearchParams(fn, options)
  }

  const putSearchParams = o => setSearchParams(p => ({ ...p, ...o }))

  return [Object.fromEntries(params), setSearchParams, putSearchParams]
}
export default useParams

export const useParam = name => {
  const [params] = useParams()
  console.log('params', params)
  return params[name] || params.hasOwnProperty(name)
}
