import { Close } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { useNavigate } from 'react-router'

export default function Drawer({ children, onClose, ...props }) {
  const navigate = useNavigate()

  const handleClose = e => onClose?.(e) || navigate(-1)

  return (
    <MuiDrawer
      {...props}
      anchor={'right'}
      onClose={handleClose}
      PaperProps={{
        sx: {
          //p: 4,
          maxWidth: 400,
          width: '100%',
        },
      }}
    >
      <Box sx={{ p: 4, position: 'relative' }}>
        <IconButton
          sx={{ position: 'fixed', top: 16, right: 16 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        {children}
      </Box>
    </MuiDrawer>
  )
}
