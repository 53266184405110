import { Box, Button, Collapse, Stack } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'

import { Replay, Search } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import debounce from 'lodash.debounce'
import React from 'react'

export default function SearchFormContainer({
  children,
  setSearch,
  defaultOpen = false,
  alwaysOpen,
  defaultValues,
  compact,
  spacing = compact ? 1.5 : 2,
  width = compact ? '120px' : '180px',
  sx,
  // in: propsIn,
}) {
  const formContext = useForm({ defaultValues, shouldUnregister: true })

  const reset = () => {
    formContext.reset()
    setSearch({})
  }

  const [open, setOpen] = React.useState(alwaysOpen || defaultOpen)

  const toggleOpen = () => {
    setOpen(v => !v)
    reset()
  }

  const handleSuccess = data => setSearch(data)
  const debouncedHandleSuccess = debounce(handleSuccess, 500, {
    leading: true,
  })

  // submit on change
  const { handleSubmit, watch } = formContext
  React.useEffect(() => {
    const subscription = watch(handleSubmit(debouncedHandleSuccess))
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const containerRef = React.useRef()
  React.useEffect(() => {
    if (containerRef.current)
      containerRef.current.querySelector('input[type=text]')?.focus()
  }, [containerRef.current])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormContainer
        formContext={formContext}
        // onSuccess={handleSuccess}
      >
        {!alwaysOpen && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mb: 2,
              justifyContent: 'space-between',
              color: '#555',
              ...sx,
              // borderColor: '#aaa',
            }}
          >
            {/* <Button type="submit" endIcon={<Search />}>
              Search
            </Button> */}

            <Button
              variant="text"
              sx={{ mb: 2, borderColor: 'primary' }}
              color="primary"
              // sx={{ mb: 2, borderColor: open ? 'primary' : '#999' }}
              // color={open ? 'primary' : 'inherit'}
              onClick={toggleOpen}
              endIcon={open ? <Close /> : <Search />}
              // variant="text"
              // color="inherit"
              // size="small"
            >
              {open ? 'Close' : 'Search and filter'}
            </Button>

            <Button
              type="reset"
              onClick={reset}
              sx={{ mb: 2, opacity: open ? 1 : 0, transition: 'opacity .3s' }}
              endIcon={<Replay />}
              color="error"
              variant="text"
            >
              Reset
            </Button>
          </Stack>
        )}

        <Collapse in={open || alwaysOpen}>
          <Box
            ref={containerRef}
            sx={{
              display: 'grid',
              gap: spacing,
              gridTemplateColumns: `repeat(auto-fill, minmax(${width}, 1fr))`,
              gridAutoFlow: 'row',
              width: '100%',
              alignItems: 'center',
              // '&> * ': { minWidth: 200 },
              mb: 3,
              borderBottom: alwaysOpen ? 'none' : '1px solid #ddd',
              pb: alwaysOpen ? 0 : 2,
            }}
          >
            {children}
          </Box>
        </Collapse>
      </FormContainer>
    </LocalizationProvider>
  )
}
