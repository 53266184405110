import { Download, Send, Share } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import {
  CheckboxButtonGroup,
  CheckboxElement,
  FormContainer,
  MultiSelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import client from '~/client'
import useDistributors from '~/routes/Proposals/FastQuote/useDistributors'
import SubmitButton from '~/components/Data/SubmitButton'
import ConsistentDialog from '~/components/Modals/ConsistentDialog'

const checkboxFields = [
  { id: 'price', label: 'Prices' },
  { id: 'laborRates', label: 'Labor rates' },
  { id: 'discount', label: 'Discounts' },
]

export const completedProposalExportFields = proposal => {
  const enablePrices = proposal.contents?.lineItems?.length > 0
  const enableLabourRates = proposal.contents?.project?.customLabourRates?.some(
    ({ quantity }) => quantity > 0
  )
  const enableDiscounts = proposal.contents?.project?.discounts?.length > 0
  const enabledFields = [enablePrices, enableLabourRates, enableDiscounts]

  const filteredCheckboxFields = checkboxFields.filter(
    (_, i) => enabledFields[i]
  )

  return filteredCheckboxFields
}
// THIS KEEPS CLOSING BECAUSE IT GETS NEW DATA WITH NEW KEYS?
export default function ProposalExportDialog({
  proposal,
  open = !!proposal,
  onClose,
  ...rest
}) {
  const userInfo = client.getUserInfo()
  const formContext = useForm({
    defaultValues: {
      subject: 'Materials list for proposal',
      body: `Hello,\nPlease find attached a material list for a Unico job.\n\nCan you please fill out the appropriate columns and send it back to me so I can coordinate timings with the client.\n\n
Please contact me any time if you have questions.\n${userInfo.email}\n${userInfo.phoneNumber}


Thank you,\n\n${userInfo.firstName} ${userInfo.lastName}\n${userInfo.address?.line1}\n${userInfo.address?.line2}\n${userInfo.address?.state} ${userInfo.address?.zipCode}
${userInfo.email}`,
    },
  })

  const { distributorsOptions, getDistributor } = useDistributors()

  const { getValues, watch } = formContext
  const distributorIds = watch('distributorIds')
  const canSend = distributorIds?.length

  const [isSending, setIsSending] = React.useState(false)

  const handleSendClick = async () => {
    setIsSending(true)
    try {
      const data = getValues()
      const { distributorIds, include, subject, body, cc } = data

      const url = `proposal/export-materials/${proposal?.proposalId}/?${
        include?.reduce((s, v) => `${s}&${v}=true`, '') || ''
      }`

      // const params = new URLSearchParams()
      // include.forEach(v => params.append(v, 'true'))
      // console.log(params.toString())

      const res = await client.post(url, {
        distributorIds,
        subject,
        body,
        sendToMyself: cc,
      })

      enqueueSnackbar(
        `Materials list has been emailed to ${
          distributorIds.length === 1
            ? getDistributor(distributorIds[0])?.companyName || 'distributor'
            : `${distributorIds.length} distributors`
        }`,
        { variant: 'success' }
      )
    } finally {
      setIsSending(false)
    }
  }

  const [isDownloading, setIsDownloading] = React.useState(false)
  const handleDownloadClick = async () => {
    setIsDownloading(true)
    try {
      const data = getValues()
      const { include } = data
      const url = `proposal/export-materials/${proposal?.proposalId}/?${
        include?.reduce((s, v) => `${s}&${v}=true`, '') || ''
      }`

      const res = await client.get(url)
      const { filename, fileContent } = res

      const blob = new Blob([fileContent], { type: 'text/plain' })
      const objectURL = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = objectURL
      anchor.download = filename
      document.body.appendChild(anchor) // we need to append the element to the dom -> otherwise it will not work in firefox
      anchor.click()
      anchor.remove() //afterwards we remove the element again
    } finally {
      setIsDownloading(false)
    }
  }

  if (!proposal?.proposalId) return null

  const enabledFields = completedProposalExportFields(proposal)

  return (
    <ConsistentDialog
      title="Send materials list"
      {...{ open, onClose }}
      maxWidth="sm"
      fullWidth
    >
      <FormContainer formContext={formContext}>
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            sx={{ alignItems: 'flex-start' }}
          >
            <CheckboxButtonGroup
              row
              name="include"
              label="Include"
              options={enabledFields}
            />
            <Divider flexItem />

            {/* <CheckboxButtonGroup
            name="include"
            label="Send to"
            options={distributorsOptions}
            // sx={{ mt: 2, display: 'block' }}
          /> */}
            <MultiSelectElement
              xs={12}
              md={6}
              name="distributorIds"
              fullWidth
              label="Choose distributors"
              multiple
              options={distributorsOptions}
              showCheckbox
              renderValue={selected =>
                selected
                  .map(
                    id =>
                      distributorsOptions.find(option => option.id === id).label
                  )
                  .join(', ')
              }
            />
            {/* {distributors?.length || true && <EmailFields />} */}
            <TextFieldElement
              fullWidth
              required
              type="text"
              label="Subject"
              name="subject"
              // placeholder=""
            />
            <TextFieldElement
              fullWidth
              multiline
              minRows={3}
              // rows={3}
              required
              type="text"
              label="Message body"
              name="body"
              placeholder="Write a custom message here"
            />
            <CheckboxElement name="cc" label="Send a copy to myself" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            sx={{ width: '100%', justifyContent: 'space-between' }}
          >
            <SubmitButton
              type="button"
              endIcon={<Send />}
              color="success"
              onClick={handleSendClick}
              disabled={!canSend}
              isSubmitting={isSending}
            >
              Send email{distributorIds?.length > 1 && 's'}
            </SubmitButton>
            <SubmitButton
              type="button"
              endIcon={<Download />}
              onClick={handleDownloadClick}
              isSubmitting={isDownloading}
            >
              Download
            </SubmitButton>
          </Stack>
        </DialogActions>
      </FormContainer>
    </ConsistentDialog>
  )
}

export function ProposalExportButton({ proposal, ...rest }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={rest.endIcon ? undefined : <Share />}
        // disabled={!completedProposalExportFields(proposal)?.length}
        {...rest}
      >
        Materials
      </Button>
      <ProposalExportDialog
        proposal={proposal}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
