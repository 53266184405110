import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import BusinessIcon from '@mui/icons-material/Business'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import GridViewIcon from '@mui/icons-material/GridView'
import HomeIcon from '@mui/icons-material/Home'
import HubOutlinedIcon from '@mui/icons-material/HubOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import PersonIcon from '@mui/icons-material/Person'
import CompanyUsers from '~/routes/Company/Users'
import Notifications from '~/routes/Notifications'
import NotificationsBadge from '~/routes/Notifications/NotificationsBadge'
import SendNotification from '~/routes/Notifications/SendNotification'
import Profile from '~/routes/Profile'
import Proposals from '~/routes/Proposals'
import StepComplete from '~/routes/Proposals/FastQuote/StepComplete'

import client from '~/client'

import Error from '~/components/Layouts/Error'

import ForgotPassword from '~/routes/Auth/ForgotPassword'
import ProtectedRoute from '~/routes/Auth/ProtectedRoute'
import ResetPassword from '~/routes/Auth/ResetPassword'
import SignIn from '~/routes/Auth/SignIn'
import SignOut from '~/routes/Auth/SignOut'

import { Navigate } from 'react-router'
import CompanyAssets from '~/routes/Company/Assets'
import CompanyProfile from '~/routes/Company/Profile'
import Maintenance from '~/routes/Maintenance'
import DistributorPricing from '~/routes/Pricing/BranchPricing'

import { roles } from '~/client/StackClient'
import ConfirmSignup from '~/routes/Auth/ConfirmSignup'
import SignUp from '~/routes/Auth/SignUp'
import ContractorNetworkContractingFirms from '~/routes/Relationships/ContractingFirms'
import RepGroupProposals from '~/routes/Relationships/Proposals'
import SuperadminCompanies from '~/routes/UnicoAdmin/Companies'
import SuperadminUsers from '~/routes/UnicoAdmin/Users'

import TextTemplates from '~/routes/Proposals/TextTemplates'

import {
  BabyChangingStation,
  Checklist,
  Dashboard as DashboardIcon,
  DescriptionOutlined,
} from '@mui/icons-material'
import Onboarding from '~/routes/Onboarding'
import ProductCatalogue from '~/routes/ProductCatalogue'

import ContractingFirmUsers from '~/routes/Relationships/ContractingFirms/ContractingFirmUsers'
import ContractorNetworkUsers from '~/routes/Relationships/ContractorNetworkUsers'
import DistributorBranches from '~/routes/Relationships/DistributorBranches'
import DistributorBranch from '~/routes/Relationships/DistributorBranches/Branch'
import DistributorUsers from '~/routes/Relationships/DistributorUsers'

import Estimates from '~/routes/Pricing/Estimates'
import CompleteEstimate from '~/routes/Pricing/Estimates/Estimate'
import LayoutRoot from './components/Layouts/LayoutRoot'
import LayoutSalesApp from './components/Layouts/SalesApp/LayoutSalesApp'
import LayoutSalesAppTabs from './components/Layouts/SalesApp/LayoutSalesAppTabs'

import ProposalOverview from '~/routes/Proposals/Overview'
import SalesResources from '~/routes/SalesResources'
import ReplacementGuidePage from './routes/Proposals/ReplacementGuide'
import DistributorBranchContractingFirmUsers from '~/routes/Relationships/DistributorBranchContractingFirmUsers'
import RepGroupDistributorUsers from '~/routes/Relationships/RepGroupDistributorUsers'
// import HomeownerProposalDownloadPdf from '~/routes/ViewProposal/DownloadPdf'

// const ElementFromLoader = async props => {
//   const { Component } = useLoaderData()
//   return <Component {...props} />
// }

// const fastQuoteLoader = async () => {
//   const { default: Component } = await import('~/routes/Proposals/FastQuote')
//   return { Component }
// }

const mapRoutes = (routes, depth = 0, superPath = '') =>
  routes.map(route => ({
    ...route,
    handle: {
      ...route.handle,
      depth,
      fullPath:
        '/' + [superPath, route.path].filter(v => v && v !== '/').join('/'),
    },
    children: route.children
      ? mapRoutes(
          route.children,
          depth + 1,
          [superPath, route.path].filter(v => v && v !== '/').join('/')
        )
      : undefined,
  }))

// much functionality across the app depends on routes being defined
// more strictly than react-router allows. eg no trailing paths

const routes = [
  {
    // path: '/',
    element: <LayoutRoot />,
    handle: { title: '' },
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <SignIn />,
        // handle: { title: 'Dashboard', Icon: EqualizerIcon },
        errorElement: <Error />,
      },
      {
        path: 'sign-out',
        element: <SignOut />,
        handle: { title: 'Log Out', Icon: LogoutIcon, nav: false },
        errorElement: <Error />,
        // loader: client.signOut(),
      },
      {
        path: 'sign-in',
        element: <SignIn />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'confirm-signup',
        element: <ConfirmSignup />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
        handle: { title: null },
        errorElement: <Error />,
      },
      // {
      //   path: 'view-proposal',
      //   element: <ViewProposal />,
      //   handle: { title: 'View proposal' },
      //   errorElement: <Error />,
      // },
      {
        path: 'onboarding',
        element: <Onboarding />,
        handle: { nav: false },
        errorElement: <Error />,
        // loader: client.signOut(),
      },
      {
        path: 'view-proposal/:proposalId',
        handle: { title: 'View proposal' },
        errorElement: <Error />,
        children: [
          {
            index: true,
            async lazy() {
              const { default: Component } = await import(
                '~/routes/ViewProposal'
              )
              return { Component }
            },
          },
          {
            path: 'download-pdf',
            handle: { title: 'View proposal PDF' },
            async lazy() {
              const { default: Component } = await import(
                '~/routes/ViewProposal/DownloadPdf'
              )
              return { Component }
            },
            // element: <HomeownerProposalDownloadPdf />,
          },
        ],
      },
      {
        path: 'maintenance',
        element: <Maintenance />,
      },
      /// NEW APP
      /// NEW APP
      /// NEW APP
      /// NEW APP
      /// NEW APP
      /// NEW APP
      /// NEW APP
      {
        path: 'app',
        element: (
          <ProtectedRoute isAllowed={client.isSignedIn} redirectPath="/sign-in">
            <LayoutSalesApp />
          </ProtectedRoute>
        ),
        handle: { title: 'Home', Icon: HomeIcon },
        children: [
          {
            index: true,
            element: <Navigate to="dashboard" />,
          },
          {
            path: 'dashboard',
            async lazy() {
              const isAdmin = client.hasRoles([
                roles.unico_admin,
                roles.rep_admin,
                roles.rep_sales_manager,
                roles.contract_firm_admin,
              ])
              const { default: Component } = await import(
                isAdmin
                  ? '~/routes/Dashboard'
                  : '~/routes/Dashboard/WelcomePage'
              )
              return { Component }
            },
            handle: { title: 'Dashboard', Icon: EqualizerIcon, nav: true },
            errorElement: <Error />,
          },
          {
            path: 'notifications',
            handle: {
              title: 'Notifications',
              Icon: NotificationsNoneIcon,
              nav: true,
              // menuAdornment: loaderData => (
              //   <Badge badgeContent={3} sx={{ ml: 2 }} color="primary" />
              // ),
              menuAdornment: () => <NotificationsBadge />,
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <Notifications />,
                handle: {
                  // title: 'Notifications',
                  // Icon: NotificationsNoneIcon,
                  nav: true,
                  // menuAdornment: () => <NotificationsBadge />,
                },
              },
            ],
          },
          {
            path: 'proposals',
            handle: {
              title: 'Proposals',
              Icon: DescriptionOutlined,
              nav: true,
            },
            element: <LayoutSalesAppTabs title="Proposals" />,
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <Navigate to="my-proposals" />,
              },
              {
                path: 'my-proposals',
                // element: <Proposals />,
                handle: {
                  title: 'My Proposals',
                  Icon: GridViewIcon,
                  nav: () =>
                    client.hasRoles([
                      roles.demo_user,
                      roles.contract_firm_admin,
                      roles.contractor,
                      roles.rep_admin,
                      roles.rep_sales_manager,
                      roles.distributor_admin,
                      roles.distributor_user,
                      roles.unico_admin,
                      roles.unico_user,
                      roles.super_admin,
                    ]),
                },
                children: [
                  {
                    index: true,
                    // element: <Navigate to="overview" />,
                    element: <Proposals />,
                  },
                  {
                    path: ':proposalId',
                    element: <ProposalOverview />,
                    handle: {
                      title: 'Proposal overview',
                    },
                    errorElement: <Error />,
                  },
                ],
                errorElement: <Error />,
              },
              {
                path: 'fast-quote',
                handle: {
                  title: 'FastQuote',
                  Icon: FormatQuoteIcon,
                  nav: () =>
                    client.hasRoles([
                      roles.demo_user,
                      roles.contract_firm_admin,
                      roles.contractor,
                      roles.rep_admin,
                      roles.rep_sales_manager,
                      roles.distributor_admin,
                      roles.distributor_user,
                      roles.unico_admin,
                      roles.unico_user,
                      roles.super_admin,
                    ]),
                },
                errorElement: <Error />,
                children: [
                  {
                    index: true,
                    async lazy() {
                      const { default: Component } = await import(
                        '~/routes/Proposals/FastQuote'
                      )
                      return { Component }
                    },
                  },
                  {
                    path: 'success/:proposalId',
                    element: <StepComplete />,
                    handle: { title: 'Proposal sent' },
                    errorElement: <Error />,
                  },
                ],
              },
              {
                path: 'replacement-guide',
                element: <ReplacementGuidePage />,
                handle: { nav: true, title: 'Replacement guide' },
              },
              {
                path: 'settings',
                // index: true,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Proposals/Settings'
                  )
                  return { Component }
                },
                // element: <ProposalSetttings />,
                handle: {
                  title: 'Settings',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },
              {
                path: 'equipment-items',
                // element: <EquipmentItems />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Proposals/EquipmentItems'
                  )
                  return { Component }
                },
                handle: {
                  title: 'Non-Unico equipment',
                  nav: () => client.hasRoles([roles.contract_firm_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'equipment-templates',
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Proposals/EquipmentTemplates'
                  )
                  return { Component }
                },
                // element: <EquipmentTemplates />,
                handle: {
                  title: 'Non-Unico templates',
                  nav: () => client.hasRoles([roles.contract_firm_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'edit-template/:equipmentTemplateId',
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Proposals/EditTemplate'
                  )
                  return { Component }
                },
                // element: <EditTemplate />,
                handle: {
                  title: 'Edit template',
                  nav: false,
                },
                errorElement: <Error />,
              },
              {
                path: 'text-templates',
                element: <TextTemplates />,
                handle: {
                  title: 'Homeowner messages',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },
            ],
          },
          {
            path: 'pricing',
            handle: {
              title: 'Pricing',
              Icon: Checklist,
              nav: () =>
                client.hasRoles([
                  roles.distributor_admin,
                  roles.distributor_user,
                ]),
            },
            element: <LayoutSalesAppTabs />,
            children: [
              {
                index: true,
                element: <Navigate to="requests" />,
              },
              {
                path: 'requests',
                // element: <Estimates />,
                handle: { title: 'Pricing requests', nav: true },
                children: [
                  {
                    index: true,
                    element: <Estimates />,
                  },
                  {
                    path: ':distributorEstimateId',
                    element: <CompleteEstimate />,
                    handle: {
                      title: 'Request for quote',
                      nav: () => false,
                    },
                    errorElement: <Error />,
                  },
                ],
              },
              {
                path: 'company-pricing/',
                element: <DistributorPricing />,
                handle: {
                  title: 'Company pricing',
                  Icon: DescriptionOutlined,
                  nav: () =>
                    client.hasRoles([
                      roles.distributor_admin,
                      roles.unico_admin,
                    ]),
                },
                errorElement: <Error />,
              },
            ],
          },

          {
            path: 'product-catalogue',
            // element: <LayoutSalesAppTabs />,
            handle: {
              title: 'Product catalog',
              Icon: AutoStoriesIcon,
              // nav: () => client.hasRoles(),
              nav: true,
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <ProductCatalogue />,
              },
            ],
          },
          {
            path: 'relationships',
            handle: {
              title: 'Relationships',
              Icon: HubOutlinedIcon,
              nav: () =>
                client.hasRoles([
                  roles.rep_admin,
                  roles.rep_sales_manager,
                  roles.distributor_company_admin,
                  roles.distributor_company_user,
                  roles.contract_firm_admin,
                  roles.rep_admin,
                  roles.rep_sales_manager,
                  roles.distributor_admin,
                  roles.distributor_user,
                ]),
            },
            element: <LayoutSalesAppTabs />,
            errorElement: <Error />,
            children: [
              // cf firms
              {
                path: 'proposals',
                element: <RepGroupProposals />,
                handle: {
                  title: 'Contractor proposals',
                  nav: () =>
                    client.hasRoles([
                      roles.rep_admin,
                      roles.rep_sales_manager,
                      roles.distributor_admin,
                      roles.distributor_user,
                    ]),
                },
                errorElement: <Error />,
              },
              {
                path: 'contracting-firm-users/:contractingFirmId',
                element: <ContractingFirmUsers />,
                handle: {
                  title: 'Contracting firm users',
                  // nav: () => true,
                },
              },
              {
                path: 'contracting-firms',
                element: <ContractorNetworkContractingFirms />,
                handle: {
                  title: 'Contracting firms',
                  nav: () =>
                    client.hasRoles([
                      roles.rep_admin,
                      roles.rep_sales_manager,
                      roles.distributor_admin,
                      roles.distributor_user,
                    ]),
                },
                errorElement: <Error />,
              },
              {
                path: 'contractor-network-users',
                element: <ContractorNetworkUsers />,
                handle: {
                  title: 'Contractor users',
                  nav: () =>
                    client.hasRoles([roles.rep_admin, roles.rep_sales_manager]),
                },
                errorElement: <Error />,
              },
              // distro
              {
                path: 'distributor-users',
                element: <DistributorUsers />,
                handle: {
                  title: 'Distributor users',
                  nav: () =>
                    client.hasRoles([
                      // roles.rep_admin, // doesn't work for reps just distro users
                      // roles.rep_sales_manager,
                      roles.distributor_company_admin,
                      roles.distributor_company_user,
                    ]),
                },
                errorElement: <Error />,
              },
              {
                path: 'distributor-branches',
                handle: {
                  title: 'Branches',
                  // nav: true,
                  nav: () =>
                    client.hasRoles([
                      roles.rep_admin,
                      roles.rep_sales_manager,
                      roles.distributor_company_admin,
                      roles.distributor_company_user,
                      roles.contract_firm_admin,
                    ]),
                },
                errorElement: <Error />,
                children: [
                  {
                    index: true,
                    element: <DistributorBranches />,
                  },
                  {
                    path: ':branchId',
                    element: <DistributorBranch />,
                    handle: {
                      title: 'Branch',
                      nav: false,
                    },
                    errorElement: <Error />,
                  },
                ],
              },
              {
                path: 'distributor-branch-contracting-firm-users',
                element: <DistributorBranchContractingFirmUsers />,
                handle: {
                  title: 'Contractors',
                  nav: () =>
                    client.hasRoles([
                      // roles.rep_admin, // doesn't work for reps just distro users
                      // roles.rep_sales_manager,
                      roles.distributor_admin,
                      roles.distributor_user,
                    ]),
                },
                errorElement: <Error />,
              },
              {
                path: 'rep-group-distributor-users',
                element: <RepGroupDistributorUsers />,
                handle: {
                  title: 'Distributor users',
                  nav: () =>
                    client.hasRoles([roles.rep_admin, roles.rep_sales_manager]),
                },
                errorElement: <Error />,
              },
            ],
          },
          {
            path: 'company',
            handle: {
              title: 'Company',
              Icon: BusinessIcon,
              nav: () =>
                client.hasRoles([
                  roles.contract_firm_admin,
                  roles.rep_admin,
                  roles.distributor_admin,
                  roles.distributor_company_admin,
                  roles.unico_admin,
                  roles.super_admin,
                ]),
            },
            element: <LayoutSalesAppTabs />,
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <Navigate to="profile" />,
              },
              {
                path: 'profile',
                element: <CompanyProfile />,
                handle: {
                  title: 'Profile',
                  nav: () => true,
                },
                errorElement: <Error />,
              },
              {
                path: 'users',
                element: <CompanyUsers />,
                handle: {
                  title: 'Users',
                  nav: () => true,
                },
                errorElement: <Error />,
              },
              {
                path: 'assets',
                element: <CompanyAssets />,
                handle: {
                  title: 'Assets',
                  nav: () => true,
                },
                errorElement: <Error />,
              },
            ],
          },
          {
            path: 'account',
            element: <Profile />,
            handle: { title: 'Account', Icon: PersonIcon, nav: true },
            errorElement: <Error />,
          },
          {
            path: 'unico-admin',
            handle: {
              title: 'Unico admin',
              Icon: AutoFixHighIcon,
              nav: () => client.hasRoles([roles.unico_admin]),
            },
            element: <LayoutSalesAppTabs />,
            errorElement: <Error />,
            children: [
              { index: true, element: <Navigate to="companies" /> },
              {
                path: 'companies',
                element: <SuperadminCompanies />,
                handle: {
                  title: 'Companies',
                  nav: () => client.hasRoles([roles.unico_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'users',
                element: <SuperadminUsers />,
                handle: {
                  title: 'Users',
                  nav: () => client.hasRoles([roles.unico_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'send-notification',
                element: <SendNotification />,
                handle: {
                  title: 'Send notification',
                  // Icon: EmailIcon,
                  nav: () => client.hasRoles([roles.unico_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'export',
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/UnicoAdmin/Export'
                  )
                  return { Component }
                },
                handle: {
                  title: 'Export',
                  nav: () => client.hasRoles(),
                  // nav: isDev(),
                },
                errorElement: <Error />,
              },
            ],
          },
          {
            path: 'sales-resources',
            element: <SalesResources />,
            handle: {
              title: 'Sales Resources',
              nav: () => client.hasRoles(),
              Icon: DashboardIcon,
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                // element: <ViewBoardCollection />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/SalesResources/ViewBoardCollection'
                  )
                  return { Component }
                },
              },
              {
                path: ':boardId/',
                // element: <ViewBoard />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/SalesResources/ViewBoard'
                  )
                  return { Component }
                },
                // children: [
                //   {
                //     path: ':cardId/',
                //     element: <div>View card</div>,
                //   },
                // ],
              },
              {
                path: ':boardId/:cardId/',
                // element: <ViewCard />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/SalesResources/ViewCard'
                  )
                  return { Component }
                },
              },
            ],
          },
          {
            path: 'sales-resources-assets',
            // element: <SalesResourcesAssets />,
            async lazy() {
              const { default: Component } = await import(
                '~/routes/SalesResources/Assets'
              )
              return { Component }
            },
            handle: {
              title: 'Sales Resources Assets',
              // nav: () => client.hasRoles(),
              Icon: DashboardIcon,
            },
            errorElement: <Error />,
          },
          {
            path: 'developer-admin',
            handle: {
              title: 'Developer admin',
              Icon: BabyChangingStation,
              // nav: () => client.hasRoles() && isDev(),
            },
            children: [
              {
                index: true,
                element: <Navigate to="fast-filter" />,
              },
              {
                path: 'fast-filter',
                // element: <FastFilter />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/UnicoAdmin/FastFilter'
                  )
                  return { Component }
                },
                handle: {
                  title: 'FastFilter',
                  Icon: FilterListIcon,
                  nav: () => client.hasRoles(),
                },
                errorElement: <Error />,
              },
            ],
          },
        ],
      },
    ],
  },
]

console.log('ROOOOTS', mapRoutes(routes))

export default mapRoutes(routes)
