import AddIcon from '@mui/icons-material/Add'
import { Box, Stack, Typography } from '@mui/material'
import { FormContainer, useForm } from 'react-hook-form-mui'
import { useLocation, useNavigate } from 'react-router'
import AddressFields from '~/routes/Company/Profile/AddressFields'
import CompanyDetailsFields from '~/routes/Company/Profile/CompanyDetailsFields'
import useCompanyDetails from '~/routes/Company/Profile/useCompanyDetails'
import CompanyAdminFields from '~/components/Companies/CompanyAdminFields'
import SubmitButton from '~/components/Data/SubmitButton'
import React from 'react'
import LoadingIndicators from '~/components/Data/LoadingIndicators'
import ContactEmails from '~/routes/Company/Profile/ContactEmails'
import ProposalSetupFields from '~/routes/Proposals/Settings/ProposalSetupFields'
import client from '~/client'

export default function EditCompanyForm() {
  const navigate = useNavigate()
  const location = useLocation()

  const { data, updateCompany, isLoading, isSubmitting } = useCompanyDetails(
    location.state?.data
  )

  const formContext = useForm({
    defaultValues: { ...location.state?.data, ...data },
    shouldUnregister: true,
  })

  React.useEffect(() => {
    if (!data?.repCompanyContractingFirms?.length) return

    const repCompanyId = data.repCompanyContractingFirms.find(
      rel => rel.contractingFirmId === data.companyId
    )?.repCompanyId

    if (repCompanyId) formContext.setValue('repCompanyId', repCompanyId)

    console.log({ data, repCompanyId })
  }, [data])

  console.log('EditCompanyForm', {
    location,
    data,
    formContext,
    values: formContext.getValues(),
  })

  const companyType = formContext.watch('companyType')

  return (
    <>
      <LoadingIndicators {...{ isLoading, isSubmitting }} />
      <Typography variant="h3" sx={{ mb: 2, mt: 3 }}>
        Edit company
      </Typography>
      <FormContainer onSuccess={updateCompany} formContext={formContext}>
        <Stack spacing={3}>
          <Typography variant="h4">Company details</Typography>
          <CompanyDetailsFields />

          <CompanyAdminFields />

          <Typography variant="h4">Address</Typography>

          <AddressFields />

          <Typography variant="h4">Contact emails</Typography>
          <ContactEmails />

          {client.hasRoles(['unico_admin']) &&
            ['contractingFirm', 'repCompany'].includes(companyType) && (
              <>
                <Typography variant="h4">Proposal setup</Typography>
                <ProposalSetupFields />
              </>
            )}

          <SubmitButton
            // disabled={!username || !password}
            sx={{ flexShrink: 0, alignSelf: 'start' }}
            endIcon={<AddIcon />}
            {...{ isSubmitting }}
          >
            Save changes
          </SubmitButton>
        </Stack>
      </FormContainer>
    </>
  )
}
