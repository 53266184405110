export default function printElement(
  el,
  {
    title = 'Materials list',
    style = '*{text-align:left;font-family:"Nunito", Helvetica, sans-serif;}',
  } = {}
) {
  let printWindow = window.open(
    '',
    'PRINT',
    'height=650,width=900,top=100,left=150'
  )

  // console.log(el)

  printWindow.document.write(`<html><head><title>${title}</title>`)
  printWindow.document.write(`<style>${style}}</style>`)
  printWindow.document.write('</head><body >')
  printWindow.document.write(el.innerHTML)
  printWindow.document.write('</body></html>')

  // printWindow.document.close(); // necessary for IE >= 10
  printWindow.focus() // necessary for IE >= 10*/

  printWindow.print()
  // printWindow.close()

  return true
}
