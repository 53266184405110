import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react'

const HeaderContext = createContext(null)

export function MultiPortalProvider({ children }) {
  const [stacks, setStacks] = useState({})

  return (
    <HeaderContext.Provider value={{ stacks, setStacks }}>
      {children}
    </HeaderContext.Provider>
  )
}

export function useMultiPortal(name, content) {
  const { stacks, setStacks } = useContext(HeaderContext)
  const id = useId()

  useEffect(() => {
    setStacks(prevStacks => {
      const stack = prevStacks[name] || []
      const exists = stack.some(item => item.id === id)

      if (exists) {
        return {
          ...prevStacks,
          [name]: stack.map(item =>
            item.id === id ? { ...item, content } : item
          ),
        }
      }

      return {
        ...prevStacks,
        [name]: [...stack, { content, id }],
      }
    })
  }, [id, content, name, setStacks])

  useEffect(() => {
    return () => {
      setStacks(prevStacks => {
        const stack = prevStacks[name] || []
        return {
          ...prevStacks,
          [name]: stack.filter(item => item.id !== id),
        }
      })
    }
  }, [id, name, setStacks])
}

export function MultiPortal({ children, name }) {
  useMultiPortal(name, children)
  return null
}

export const useMultiPortalOutlet = (name, content) => {
  const { stacks } = useContext(HeaderContext)

  return stacks[name]?.at(-1)?.content ?? content ?? null
}

export const MultiPortalOutlet = ({ name, children }) =>
  useMultiPortalOutlet(name, children)
