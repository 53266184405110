import { PictureAsPdf, Send } from '@mui/icons-material'
import { Box, Button, Card, Divider, Tooltip, Typography } from '@mui/material'
// import html2pdf from 'html2pdf.js'
import React from 'react'
import AutoTable from '~/components/Data/AutoTable/AutoTable'
import UnicoLogo from '~/components/Layouts/UnicoLogo'
import cardSx from '~/core/sx/cardSx'
import sxFlexStack from '~/core/sx/sxFlexStack'
import { camelToSentence } from '~/core/utils/formatString'
import { useReplacementGuideState } from '../Provider/ReplacementGuideProvider'
import SummaryProductsTable from './SummaryProductsTable'
// import MaterialsList from '../../../ViewProposal/MaterialsList'
import useDistributors from '../../FastQuote/useDistributors'
import printElement from '../printElement'
import SendMaterialsListDialog from './SendMaterialsListDialog'
import StepButtons from './StepButtons'
import isDev from '~/config/isDev'

const renderFields = fields =>
  Object.entries(fields || {}).map(([key, value]) => ({
    name: camelToSentence(key),
    value: camelToSentence(value.toString()),
  }))

const microTableSx = {
  maxWidth: 400,
  '& td:nth-child(1)': { width: 300 },
  '& thead': { display: 'none' },
}

const makeExistingSummary = fields =>
  fields.existing && [
    { name: 'Tonnage', value: fields.existing.tonnage.replace(/_/g, '.') },
    {
      name: 'System orientation',
      value: camelToSentence(fields.existing.orientation),
    },
    {
      name: 'Does the return need replacing',
      value: fields.existing.replaceReturnBox ? 'Yes' : 'No',
    },
    {
      name: 'Does the system have a heating coil',
      value: fields.existing.heatingCoil ? 'Yes' : 'No',
    },
    {
      name: 'Does the system have a cooling coil',
      value: fields.existing.coolingCoil ? 'Yes' : 'No',
    },
  ]

const makeReplacementSummary = fields =>
  fields.replacement && [
    {
      name: 'System orientation',
      value: camelToSentence(fields.replacement.orientation),
    },
    {
      name: 'Blower direction',
      value: camelToSentence(fields.replacement.direction),
    },
    {
      name: 'Outlet adapter',
      value: camelToSentence(fields.replacement.outletAdapter) + '"',
    },
    {
      name: 'Do you want to add a heating coil',
      value: fields.replacement.heatingCoil ? 'Yes' : 'No',
    },
  ]

const makeDesignCheckSummary = fields =>
  fields.designCheck && [
    {
      name: 'Existing number of outlets',
      value: fields.designCheck.outletQuantity,
    },
    {
      name: 'Existing duct size matches',
      value: fields.designCheck.ductSize ? 'Yes' : 'No',
    },
    {
      name: 'Will the new blower fit',
      value: fields.designCheck.blowerWillFit ? 'Yes' : 'No',
    },
  ] //.filter(row => typeof row.value !== 'undefined')

const today = () =>
  new Date().toISOString().replace('T', ' ').replace(/\:/g, '-').split('.')[0]

const pdfOptions = () => ({
  margin: 1.5,
  filename: `Unico replacement materials list ${today()}.pdf`,
  // image: { type: 'jpeg', quality: 0.98 },
  html2canvas: { scale: 4 },
  jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
})

export default function Complete({ onNextClick, onPrevClick, ...rest }) {
  const { fields, lineItems } = useReplacementGuideState()

  const pdfRef = React.useRef()
  const handleSavePdf = () => {
    // html2pdf(pdfRef.current, pdfOptions())
    printElement(pdfRef.current)
  }

  const [open, setOpen] = React.useState(false)

  const { distributorsOptions } = useDistributors()
  const canSend = distributorsOptions?.length > 0

  // console.log('RPLSTATE', state)

  return (
    <>
      <SendMaterialsListDialog
        lineItems={lineItems}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Card sx={{ ...cardSx, ...sxFlexStack, gap: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'flex-start', sm: 'center' },
            width: '100%',
            gap: 2,
            // mb: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4">Replacement summary</Typography>
            <Typography>Existing and new system overview</Typography>
          </Box>
          <div>
            <Button onClick={handleSavePdf} endIcon={<PictureAsPdf />}>
              Download PDF
            </Button>
          </div>
          {isDev() && (
            <Tooltip
              title={
                !canSend &&
                `You don't have any distributor relationships set up yet`
              }
            >
              <div>
                <Button
                  disabled={!canSend}
                  onClick={() => setOpen(true)}
                  endIcon={<Send />}
                >
                  Send to distributors
                </Button>
              </div>
            </Tooltip>
          )}
        </Box>
      </Card>
      <Card sx={{ ...cardSx, ...sxFlexStack, gap: 1 }}>
        <Box ref={pdfRef} sx={{ ...sxFlexStack, width: '100%' }}>
          <UnicoLogo />
          <Typography variant="h4">Replacement material list</Typography>
          <SummaryProductsTable />

          <Typography variant="h4">Existing system specification</Typography>
          <AutoTable
            appStylesdata={makeExistingSummary(fields)}
            sx={microTableSx}
          />

          <Typography variant="h4">Replacement system specification</Typography>
          <AutoTable
            appStylesdata={makeReplacementSummary(fields)}
            sx={microTableSx}
          />

          <Typography variant="h4">Design check</Typography>
          <AutoTable
            appStylesdata={makeDesignCheckSummary(fields)}
            sx={microTableSx}
          />
        </Box>
        <Divider />

        <StepButtons {...{ onPrevClick }} />
      </Card>
    </>
  )
}
