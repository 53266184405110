import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'

const ProtectedRoute = ({ isAllowed, redirectPath = '/sign-in', children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  // console.log('protected location', location)

  React.useEffect(() => {
    if (!isAllowed()) navigate(redirectPath, { state: { from: location } })
  }, [])

  if (!isAllowed()) return null

  return children ? children : <Outlet />
}

export default ProtectedRoute
